import { IProduct } from 'interface/product';
import { create } from 'zustand';

interface IProductsStore {
  products: IProduct[];
  setProducts: (products: IProduct[]) => void;
}

const useProductsStore = create<IProductsStore>((set) => ({
  products: [],
  setProducts: (products: IProduct[]) => set(() => ({ products })),
}));

export default useProductsStore;
