import React from 'react';
import { formatNumber } from 'utils/formatNumber';

import './User.scss';

interface IUserProps {
  username: string;

  wallet?: string;
  isShowWallet?: boolean;

  hapiScore?: number | null;
  isShowHapiScore?: boolean;

  ticketsAmount?: number;
  isShowTickets?: boolean;
  onWalletClick?: () => void;
}

const User: React.FC<IUserProps> = ({
  username,
  isShowTickets,
  ticketsAmount,
  isShowWallet,
  hapiScore,
  isShowHapiScore,
  wallet,
  onWalletClick,
}) => (
  <div className="user">
    <div className="user-img">{username?.[0] || ''}</div>
    <div className="user-info">
      <h4>{username}</h4>
      {isShowTickets && (
        <p className="user-tickets">
          <img src="images/point.png" alt="Points" loading="lazy" />
          <span>{formatNumber(ticketsAmount)}</span>
        </p>
      )}
      {isShowWallet && wallet && (
        <p className="user-wallet" onClick={onWalletClick}>
          <img src="images/hot.png" alt="Hot" loading="lazy" />
          <span>{wallet}</span>
        </p>
      )}
    </div>
    {!!isShowHapiScore && (
      <div className="hapi-score">
        <img src="images/hapi-score.png" alt="Hapi" loading="lazy" />
        <span>{hapiScore}</span>
      </div>
    )}
  </div>
);

export default React.memo(User);
