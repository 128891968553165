import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import useBattleStore from 'store/battle.store';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import useCountdown from 'hooks/useCountdown';
import { formatEnergyTimeLeft, sleep } from 'utils/time';
import { HOURS_TO_REFILL, MAX_ENERGY } from 'constants/battle';
import useProductsStore from 'store/products.store';
import { useBattleEnergyPurchase } from 'hooks/purchases/useBattleEnergyPurchase';
import useMainStore from 'store/main.store';
import { EProductId } from 'interface/product';
import { showErrorToast } from 'utils/toastUtils';
import { openPurchaseLink } from 'utils/purchase';
import { purchaseProduct } from 'api/products';
import { getBattleProfile } from 'api/battle';
import { motion } from 'framer-motion';
import { defaultAnimation } from 'styles/animations';

import './SeasonInfo.scss';

const MAX_SNOWS = MAX_ENERGY;
const ENERGY_REFRESH_INTERVAL = 3600 * HOURS_TO_REFILL;

const SeasonInfo: React.FC = () => {
  const tg = useMainStore((state) => state.tgWebApp);
  const products = useProductsStore((state) => state.products);
  const { profile, setProfile, isRefilling, setIsRefilling } = useBattleStore();
  const showModal = useModalStore((state) => state.showModal);
  const timeLeft = useCountdown(new Date(profile?.nextEnergyRefreshAt || new Date()), 1000);
  const [purchaseId, setPurchaseId] = useState<string | undefined>(undefined);

  const snows = profile?.energy || 0;
  const duels = profile?.totalPlayed || 0;
  const wins = profile?.wins || 0;
  const draws = profile?.draws || 0;

  const handleShowInfo = () => {
    showModal(EModals.SNOWBALLS_FAQ_MODAL);
  };

  const timeLeftFormatted = timeLeft ? formatEnergyTimeLeft(timeLeft) : null;
  const percentageFilled = timeLeft
    ? Math.min(100, (1 - timeLeft / ENERGY_REFRESH_INTERVAL) * 100)
    : 0;

  useEffect(() => {
    if (timeLeftFormatted && timeLeft === 1) {
      (async () => {
        try {
          await sleep(1000);
          setIsRefilling(true);
          await sleep(3000);
          const user = await getBattleProfile();
          if (user) {
            setProfile(user);
          }
        } catch (error) {
          console.error('Failed to refill energy...', error);
        } finally {
          setIsRefilling(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeftFormatted, timeLeft]);

  const onRefeelClick = async () => {
    if (profile?.energy === MAX_ENERGY) {
      return;
    }
    const productKey = products.find(
      (product) => product.id === EProductId.BATTLE_ENERGY_REFILL,
    )?.id;
    if (!productKey) {
      showErrorToast('Product not found, please contact support or try again later...');
      return;
    }
    const response = await purchaseProduct(productKey);
    setPurchaseId(response.id);
    openPurchaseLink(tg, response.purchaseUrl);
  };

  useBattleEnergyPurchase(purchaseId);

  return (
    <div className="season-info">
      {isRefilling ? (
        <motion.div className="season-info__refilling-overlay" {...defaultAnimation}>
          <img
            className="season-info__refilling-overlay-img"
            src="/images/loading-snow.gif"
            alt=""
          />
          <h2>Making Snowballs...</h2>
        </motion.div>
      ) : null}
      <div
        className={classNames('season-info__energy', {
          'season-info__energy--refeel': profile?.energy !== MAX_ENERGY,
        })}>
        <div
          className="season-info__energy-icons-wrapper"
          onClick={isRefilling ? () => {} : onRefeelClick}>
          <img
            className="season-info__energy-icon"
            src="/images/snow-season/energy.png"
            alt="Energy"
          />
          {profile?.energy !== MAX_ENERGY && !isRefilling && (
            <img className="season-info__energy-add" src="/images/snow-season/add.png" alt="+" />
          )}
        </div>
        <div className="season-info__energy-info">
          <p className="season-info__energy-amount">
            {snows}
            <span>/{MAX_SNOWS}</span>
          </p>
          {!!timeLeft && (
            <div className="season-info__energy-time-left">
              <span>{timeLeftFormatted}</span>
              <div style={{ width: `${percentageFilled}%` }}></div>
            </div>
          )}
        </div>
      </div>

      <div className="season-info__stats">
        <p>
          <span>Duels:</span>
          <span>{duels}</span>
        </p>
        <p>
          <span>Wins:</span>
          <span>{wins}</span>
        </p>
        <p>
          <span>Draws:</span>
          <span>{draws}</span>
        </p>
      </div>

      <img
        className="season-info__info-icon"
        onClick={handleShowInfo}
        src="/images/snow-season/info-icon.png"
        alt="i"
      />

      <img className="season-info__ice-1" src="/images/snow-season/ice/1.png" alt="" />
      <img className="season-info__ice-2" src="/images/snow-season/ice/2.png" alt="" />
      <img className="season-info__ice-3" src="/images/snow-season/ice/3.png" alt="" />
    </div>
  );
};

export default SeasonInfo;
