import { EBoostType } from 'constants/boost';
import { ENFT_TYPE } from './nft';
import { IDailyReward } from './user';
import { SignMessageParams, SignedMessage } from '@near-wallet-selector/core';
import { IBattle } from './battle';

export enum EModals {
  FAQ = 'FAQ',
  GIFT = 'GIFT',
  DAILY_REWARD = 'DAILY_REWARD',
  NFT_REWARD = 'NFT_REWARD',
  NFTS_INFO_MODAL = 'NFTS_INFO_MODAL',
  NFT_STAKING_INFO_MODAL = 'NFT_STAKING_INFO_MODAL',
  NFT_UNSTAKE_MODAL = 'NFT_UNSTAKE_MODAL',
  NFT_STAKING_SELECT_MODAL = 'NFT_STAKING_SELECT_MODAL',
  CONNECT_HOT_REWARD = 'CONNECT_HOT_REWARD',
  DISCONNECT_WALLET = 'DISCONNECT_WALLET',
  LEVEL_UPGRADE = 'LEVEL_UPGRADE',
  BOOST = 'BOOST',
  FITTON_CONNECT = 'FITTON_CONNECT',
  AUTO_CLAIM = 'AUTO_CLAIM',
  MEERKAT_CONNECT = 'MEERKAT_CONNECT',
  AIRDROP_AND_PVP = 'AIRDROP_AND_PVP',
  BATTLE_WAITING_MODAL = 'BATTLE_WAITING_MODAL',
  BATTLE_RESULT_MODAL = 'BATTLE_RESULT_MODAL',
  SNOW_FAQ_MODAL = 'SNOW_FAQ_MODAL',
  SNOWBALLS_FAQ_MODAL = 'SNOWBALLS_FAQ_MODAL',
  EMPTY = 'EMPTY',
}

export interface ICloseModal extends React.PropsWithChildren {
  closeModal: () => void;
}

export type IFAQModal = ICloseModal;

export type IDisconnectWalletModal = ICloseModal;

export interface IConnectHotRewardModal extends ICloseModal {
  isConfirming?: boolean;
  onSuccess: () => void;
  signMessage: (params: SignMessageParams) => Promise<SignedMessage | void>;
}

export interface INFTRewardModal extends ICloseModal {
  level: number;
  nftId: string;
  type: ENFT_TYPE;
}

export interface INFTsInfoModal extends ICloseModal {
  type: ENFT_TYPE;
}

export interface INFTStakingInfoModal extends ICloseModal {}

export interface INFTUnstakeModal extends ICloseModal {}

export interface INFTStakingSelectModal extends ICloseModal {}

export interface IDailyRewardModal extends ICloseModal {
  dailyReward: IDailyReward;
}

export interface IFittonConnectModal extends ICloseModal {
  onSubmit: () => Promise<void>;
  isLimitExceeded?: boolean;
}

export interface IMeerkatConnectModal extends ICloseModal {
  onSubmit: () => Promise<void>;
  isLimitExceeded?: boolean;
}

export interface IGiftModal extends ICloseModal {
  id: string;
  coins: number;
  tickets: number;
  description: string;
}

export interface ILevelUpgradeModal extends ICloseModal {
  nextLevel: number;
}

export interface IAutoClaimModal extends ICloseModal {
  endDate: Date | null;
}

export interface IBoostModal extends ICloseModal {
  boost: EBoostType;
  level: number;
}

export interface IBattleWaitingModal extends ICloseModal {
  navigate: (path: string) => void;
  isCloseHidden: boolean;
}

export interface IBattleResultModal extends ICloseModal {
  battle: IBattle;
}

export type IAirdropAndPvPModal = ICloseModal;

export type ISnowFAQModal = ICloseModal;

export type ISnowballsFAQModal = ICloseModal;

export type IModalsProps = {
  [EModals.FAQ]: IFAQModal;
  [EModals.GIFT]: IGiftModal;
  [EModals.DAILY_REWARD]: IDailyRewardModal;
  [EModals.NFT_REWARD]: INFTRewardModal;
  [EModals.NFTS_INFO_MODAL]: INFTsInfoModal;
  [EModals.NFT_STAKING_INFO_MODAL]: INFTStakingInfoModal;
  [EModals.NFT_UNSTAKE_MODAL]: INFTUnstakeModal;
  [EModals.NFT_STAKING_SELECT_MODAL]: INFTStakingSelectModal;
  [EModals.CONNECT_HOT_REWARD]: IConnectHotRewardModal;
  [EModals.DISCONNECT_WALLET]: IDisconnectWalletModal;
  [EModals.LEVEL_UPGRADE]: ILevelUpgradeModal;
  [EModals.BOOST]: IBoostModal;
  [EModals.FITTON_CONNECT]: IFittonConnectModal;
  [EModals.AUTO_CLAIM]: IAutoClaimModal;
  [EModals.MEERKAT_CONNECT]: IMeerkatConnectModal;
  [EModals.AIRDROP_AND_PVP]: IAirdropAndPvPModal;
  [EModals.BATTLE_WAITING_MODAL]: IBattleWaitingModal;
  [EModals.BATTLE_RESULT_MODAL]: IBattleResultModal;
  [EModals.SNOW_FAQ_MODAL]: ISnowFAQModal;
  [EModals.SNOWBALLS_FAQ_MODAL]: ISnowballsFAQModal;
  [EModals.EMPTY]: any;
};

export interface IModalInstance {
  type: EModals;
  props: IModalsProps[EModals];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.LazyExoticComponent<React.ComponentType<any>> | null;
}
