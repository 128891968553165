import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { defaultAnimation } from 'styles/animations';

import './LoadingScreen.scss';

interface ILoadingScreenProps {
  isSnow?: boolean;
}

const LoadingScreen: React.FC<ILoadingScreenProps> = ({ isSnow = false }) => {
  return !isSnow ? (
    <div className="loading-screen">
      <img className="loading-img" src="/images/loading.png" alt="Loading" />
      <img className="shadow" src="/images/shadow.png" alt="" />
    </div>
  ) : (
    <AnimatePresence>
      <motion.div className="loading-screen snow" {...defaultAnimation}>
        <img className="loading-img" src="/images/loading-snow.gif" alt="" />
        <h2>Loading...</h2>
      </motion.div>
    </AnimatePresence>
  );
};

export default LoadingScreen;
