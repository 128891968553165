import React from 'react';
import {
  EBoostType,
  MAX_SPEED_BOOST_LEVEL,
  MAX_TIME_BOOST_LEVEL,
  SPEED_BOOST,
} from 'constants/boost';
import { TIME_BOOST } from 'constants/boost';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import useFarmingStore from 'store/farming.store';

import './Boosts.scss';

const Boosts: React.FC = () => {
  const { showModal } = useModalStore();
  const timeBoostLevel = useFarmingStore((state) => state.timeBoostLevel);
  const speedBoostLevel = useFarmingStore((state) => state.speedBoostLevel);

  const timeBoost = TIME_BOOST.get(timeBoostLevel || 1);
  const speedBoost = SPEED_BOOST.get(speedBoostLevel || 1);

  const onBoostClick = (boost: EBoostType) =>
    showModal(EModals.BOOST, {
      boost: boost,
      level: boost === EBoostType.SPEED ? speedBoostLevel : timeBoostLevel,
    });

  return (
    <div className="boosts">
      <h2 className="boosts-title">
        Boosts
        <img src="images/boost.png" alt="" style={{ width: '28px', height: '28px' }} />
      </h2>
      <div className="boosts-list">
        <div className="boosts-item" onClick={() => onBoostClick(EBoostType.SPEED)}>
          <img src="images/boost-speed.png" alt="Speed" style={{ width: '40px', height: '40px' }} />
          <div className="boosts-item-info">
            <h3>
              Speed{' '}
              <label className="boost-level">
                {speedBoostLevel >= MAX_SPEED_BOOST_LEVEL ? 'MAX' : `LVL ${speedBoostLevel}`}
              </label>
            </h3>
            <p className="item-reward item-reward--points">
              <img
                src="images/point.png"
                alt="Speed Points Reward"
                style={{ width: '16px', height: '16px' }}
              />
              <span>{speedBoost!.miningSpeed}</span>/ hour
            </p>
            <p className="item-reward item-reward--tickets">
              <img
                src="images/ticket.svg"
                alt="Speed Tickets Reward"
                style={{ width: '16px', height: '16px' }}
              />
              <span>{speedBoost!.ticketsMiningSpeed}</span>/ hour
            </p>
          </div>
        </div>
        <div className="boosts-item" onClick={() => onBoostClick(EBoostType.TIME)}>
          <img src="images/boost-time.png" alt="Time" style={{ width: '40px', height: '40px' }} />
          <div className="boosts-item-info">
            <h3>
              Time{' '}
              <label className="boost-level">
                {timeBoostLevel >= MAX_TIME_BOOST_LEVEL ? 'MAX' : `LVL ${timeBoostLevel}`}
              </label>
            </h3>
            <p className="item-reward item-reward--time">
              <img
                src="images/boost-time-reward.png"
                alt="Time Reward"
                style={{ width: '16px', height: '16px' }}
              />
              <span>{timeBoost!.miningTime}</span> hours
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boosts;
