import { ETaskSlug, ETaskStatus, ETaskType, ITaskProgress } from 'interface/task';
import { DAILY_TASK_REWARD } from './main';
import { IUser } from 'interface/user';

export const HAPI_SCORE_URL = 'http://t.me/herewalletbot/app?startapp=web-score-hapi-mobi';

export const getTaskIcon = (status: ETaskStatus) => {
  switch (true) {
    case status === ETaskStatus.INITIAL:
      return 'images/arrow.svg';
    case status === ETaskStatus.COMPLETED_CLAIMED:
    case status === ETaskStatus.COMPLETED_NOT_CLAIMED:
      return 'images/task-completed-icon.png';
    case status === ETaskStatus.VERIFY_REQUESTED:
      return 'images/task-review-icon.png';
    case status === ETaskStatus.VERIFY_REJECTED:
      return 'images/task-rejected-icon.png';
  }
};

export const getDailyTaskStatus = (slug: string, tasks: ITaskProgress[]) =>
  tasks.find((task) => task.template.slug === slug)?.status || ETaskStatus.INITIAL;

export const DAILY_TASKS: ITaskProgress[] = [
  {
    id: '2',
    template: {
      id: '2',
      rewardCoins: 0,
      rewardTickets: DAILY_TASK_REWARD,
      title: 'Share X Post',
      type: ETaskType.DAILY,
      url: '',
      shortDesc: 'Share your post on X',
      longDesc: 'Share your post on X to earn rewards.',
      image: 'images/task/daily-x.png',
      proofRequired: false,
      isVisible: true,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.DAILY_X,
    },
    templateId: '2',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
  },
  {
    id: '3',
    template: {
      id: '3',
      rewardCoins: 0,
      rewardTickets: DAILY_TASK_REWARD,
      title: 'Share TikTok Post',
      type: ETaskType.DAILY,
      url: '',
      shortDesc: 'Share your post on TikTok',
      longDesc: 'Share your post on TikTok to earn rewards.',
      image: 'images/task/daily-tik-tok.png',
      proofRequired: false,
      isVisible: true,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.DAILY_TIK_TOK,
    },
    templateId: '3',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
  },
];

export const PARTNERS_TASKS: ITaskProgress[] = [
  {
    id: '4',
    template: {
      id: '4',
      rewardCoins: 50_000,
      rewardTickets: 50,
      title: 'Mint Hapi Score',
      type: ETaskType.PARTNER,
      url: '',
      shortDesc: 'Mint Hapi Score',
      longDesc: 'Mint Hapi Score to earn rewards.',
      image: 'images/hapi-score.png',
      proofRequired: false,
      isVisible: true,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: 'hapi-score-connect',
    },
    templateId: '4',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    startedAt: new Date(),
    proof: undefined,
  },
  {
    id: '5',
    template: {
      id: '5',
      rewardCoins: 50_000,
      rewardTickets: 50,
      title: 'Connect FITTON app',
      type: ETaskType.PARTNER,
      url: '',
      shortDesc: 'Connect FITTON app',
      longDesc: 'Connect FITTON app to earn rewards.',
      image: 'images/fitton-connect.png',
      proofRequired: false,
      isVisible: true,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: 'fitton-connect',
    },
    templateId: '5',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    startedAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
  },
  {
    id: '6',
    template: {
      id: '6',
      rewardCoins: 30_000,
      rewardTickets: 30,
      title: 'Join Meerkat Coin',
      type: ETaskType.PARTNER,
      url: '',
      shortDesc: 'Join Meerkat Coin',
      longDesc: 'Join Meerkat Coin to earn rewards.',
      image: 'images/meerkat-connect.jpg',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: 'meerkat-connect',
    },
    templateId: '6',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    startedAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
  },
];

export const GENERAL_TASKS: ITaskProgress[] = [
  {
    id: '1',
    template: {
      id: '1',
      rewardCoins: 10_000,
      rewardTickets: 10,
      title: 'Create Battle Profile',
      type: ETaskType.BASIC,
      url: '',
      shortDesc: 'Create Battle Profile',
      longDesc: 'Create Battle Profile to earn rewards.',
      image: 'images/task/general-battle-account.svg',
      proofRequired: false,
      isVisible: true,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.BASIC_BATTLE_ACCOUNT,
    },
    templateId: '1',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    startedAt: new Date(),
    proof: undefined,
  },
];

export interface IGeneralTask {
  icon: string;
  label: string;
  reward: number;
  status?: ETaskStatus;
  link: string;
  type: 'TELEGRAM' | 'X' | 'YOUTUBE';
}
