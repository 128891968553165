import { useState, useEffect } from 'react';

const useCountdown = (endDate: Date | null, updateInterval = 60000) => {
  const calculateTimeLeft = () => {
    if (!endDate) return 0;
    const now = new Date();
    const difference = endDate.getTime() - now.getTime();
    return difference > 0 ? Math.floor(difference / 1000) : 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeLeft(calculateTimeLeft());
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, updateInterval);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  return timeLeft;
};

export default useCountdown;
