import { IPurchase } from 'interface/product';
import api from './APIService';

export const getProducts = async () => {
  try {
    const response = await api.get('products');
    return response.data;
  } catch (error) {
    return [];
  }
};

export const purchaseProduct = async (productId: string): Promise<IPurchase> => {
  const response = await api.post('purchases', { productId });
  return response.data;
};

export const getPurchase = async (purchaseId: string): Promise<IPurchase | null> => {
  try {
    const response = await api.get(`purchases/${purchaseId}`);
    return response.data;
  } catch (error) {
    return null;
  }
};
