import React, { memo } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { Opponent, Player } from './components/Player';
import { EBattleTarget } from 'interface/battle';
import SnowballAnimation from './components/SnowballAnimation';
import SnowballCountdown from './components/Countdown';
import Points from './components/Points';
import { defaultAnimation } from 'styles/animations';
import { RoundType } from 'pages/snow-season/round/RoundPage';
import RoundButton from './components/RoundButton';
import RoundDetails from '../RoundDetails';

import './Round.scss';

const newRoundOpacity = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 1 },
};

const slideVariants = {
  hidden: { y: '-289px' },
  visible: { y: 0 },
  exit: { y: '215px' },
};

interface IRoundProps {
  id: number;
  round: RoundType;
  attackTarget?: EBattleTarget | null;
  defenseTarget?: EBattleTarget | null;
  isDisabled?: boolean;
  onStartRound?: () => void;
  onNextRound?: () => void;
  selectDefense?: (target: EBattleTarget) => void;
  selectAttack?: (target: EBattleTarget) => void;
  playerPoints?: number;
  opponentPoints?: number;
  playerLevel?: number;
  opponentLevel?: number;
  isSnowballAnimation?: boolean;
  isLuckAnimation?: boolean;
  isCountdown?: boolean;
  isBattleFinished?: boolean;
  isNextRoundButton?: boolean;
}

const Round: React.FC<IRoundProps> = ({
  id,
  round,
  attackTarget,
  defenseTarget,
  isDisabled,
  playerLevel,
  opponentLevel,
  onStartRound,
  onNextRound,
  selectAttack,
  selectDefense,
  isBattleFinished,
  isSnowballAnimation,
  isLuckAnimation,
  isCountdown,
}) => {
  const isRoundButton = round.isCurrent && !isBattleFinished;
  if (isBattleFinished) return;
  return (
    <motion.div
      key={id}
      className={classNames('round', {
        'round--ended': !round.isCurrent || round.isFinishedAnimations,
      })}
      variants={round.isCurrent ? newRoundOpacity : slideVariants}
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5, delay: round.isCurrent ? 0.5 : 0 }}>
      <h2 className="round-title">Round {id}</h2>
      <div className="round-subtitle">
        <p className="round-subtitle__action">Defend</p>
        <p className="round-subtitle__action-info">
          {round.isCurrent ? 'Select your actions!' : 'Ended'}
        </p>
        <p className="round-subtitle__action">Attack</p>
      </div>
      <div className="round-selects">
        <div className="round-selects__inner">
          <Player
            level={playerLevel}
            isHeadSelected={defenseTarget ? defenseTarget === EBattleTarget.HEAD : false}
            isBodySelected={defenseTarget ? defenseTarget === EBattleTarget.BODY : false}
            onSelect={selectDefense ? selectDefense : () => {}}
          />
          {round.isCurrent && true && (
            <SnowballAnimation
              isAnimation={isSnowballAnimation}
              isLuck={isLuckAnimation && round.isPlayerLuck}
              isLeft
            />
          )}
          <SnowballCountdown isPlay={round.isCurrent ? isCountdown : false} />
          {round.isCurrent && (
            <SnowballAnimation
              isAnimation={isSnowballAnimation}
              isLuck={isLuckAnimation && round.isOpponentLuck}
            />
          )}
          <Opponent
            level={opponentLevel}
            isHeadSelected={attackTarget ? attackTarget === EBattleTarget.HEAD : false}
            isBodySelected={attackTarget ? attackTarget === EBattleTarget.BODY : false}
            onSelect={selectAttack ? selectAttack : () => {}}
          />
          <img className="round-selects__ice" src="/images/snow-season/ice/2.png" alt="" />
        </div>
        <Points
          isFinishedAnimations={round.isFinishedAnimations}
          playerPoints={round.playerPoints}
          opponentPoints={round.opponentPoints}
        />
        {round.isShowDetails && round.details && (
          <motion.div
            animate={{ top: ['50%', '100%', '100%', '130%'] }}
            transition={{
              duration: 4,
              times: [0, 0.33, 0.66, 1],
              ease: 'linear',
            }}
            className="round-details-wrapper">
            <RoundDetails round={round.details} isFull={false} />
          </motion.div>
        )}
        {round.isCurrent && round.isFinishedAnimations && round.result && (
          <motion.img
            {...defaultAnimation}
            className="round-selects__battle-result"
            src={`/images/snow-season/battle/${round.result}.png`}
            alt=""
          />
        )}
      </div>
      {isRoundButton && (
        <RoundButton
          isNextRoundButton={id === 5 ? false : round.isNextRoundButton}
          isDisabled={isDisabled}
          isHidden={isCountdown || isSnowballAnimation || round.isShowDetails}
          onNextRound={onNextRound}
          onStartRound={onStartRound}
        />
      )}
    </motion.div>
  );
};

export default memo(Round);
