import { IUser } from './user';

export enum ETaskSlug {
  DAILY_TELEGRAM = 'share-telegram',
  DAILY_X = 'share-x',
  DAILY_TIK_TOK = 'share-tiktok',

  BASIC_TG = 'pitchtalk-tg',
  BASIC_CHAT = 'pitchtalk-chat',
  BASIC_X = 'pitchtalk-x',
  BASIC_YOUTUBE = 'pitchtalk-youtube',
  BASIC_BATTLE_ACCOUNT = 'create-battle-profile',

  PARTNER_HAPI_SCORE = 'hapi-score-connect',
  PARTNER_FITTON = 'fitton-connect',
  PARTNER_MEERKAT = 'meerkat-connect',
}

export enum ETaskStatus {
  INITIAL = 'INITIAL',
  VERIFY_REQUESTED = 'VERIFY_REQUESTED',
  VERIFY_REJECTED = 'VERIFY_REJECTED',
  COMPLETED_NOT_CLAIMED = 'COMPLETED_NOT_CLAIMED',
  COMPLETED_CLAIMED = 'COMPLETED_CLAIMED',
}

export enum ETaskType {
  BASIC = 'BASIC',
  DAILY = 'DAILY',
  PARTNER = 'PARTNER',
}

export interface ITaskTemplate {
  id: string;
  rewardCoins: number;
  rewardTickets: number;
  title: string;
  type: ETaskType;
  url: string;
  shortDesc: string;
  longDesc: string;
  image: string;
  proofRequired: boolean;
  isVisible: boolean;
  partnerData: Record<string, any>;
  taskProgress: ITaskProgress[];
  costCoins: number;
  costTickets: number;
  slug: string;
}

export interface ITaskProgress {
  id: string;
  template: ITaskTemplate;
  templateId: string;
  user: IUser;
  userId: string;
  status: ETaskStatus;
  completedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  startedAt?: Date | null;
  proof?: string;
}
