import {
  ENFT_DD_GRADE,
  ENFT_GRADE,
  ENFT_TYPE,
  ENFT_USMEN_GRADE,
  ENFT_YUP_GRADE,
} from 'interface/nft';

export const NFTBuyLinkPT = 'http://t.me/herewalletbot/app?startapp=web-mitte-pitchtalk';
export const NFTBuyLinkHERE = 'http://t.me/herewalletbot/app?startapp=web-mitte-pitchtalk';
export const NFTBuyLinkHAPI = 'https://beta.mitte.gg/?contractAddress=hapi-nearonaut.near';
export const NFTBuyLinkUSMen = 'https://beta.mitte.gg/?contractAddress=usmen.hot.tg';
export const NFTBuyLinkDD = 'https://beta.mitte.gg/?contractAddress=doubledog.hot.tg';
export const NFTBuyLinkYUP = 'https://beta.mitte.gg/?contractAddress=darai.mintbase1.near';

export const openPTNFTBuyLink = () => window.open(NFTBuyLinkPT, '_blank', 'noreferrer noopener');

export const NFTS_ACTIVE = {
  [ENFT_TYPE.PITCHTALK]: true,
  [ENFT_TYPE.HOT]: false,
  [ENFT_TYPE.HAPI]: true,
  [ENFT_TYPE.USMEN]: true,
  [ENFT_TYPE.DD]: true,
  [ENFT_TYPE.YUP]: true,
};

export const NFT_REWARDS_PT = new Map<number, { coins: number; tickets: number; grade: string }>([
  [1, { coins: 300_000, tickets: 100, grade: 'Whale' }],
  [2, { coins: 250_000, tickets: 60, grade: 'Shark' }],
  [3, { coins: 200_000, tickets: 50, grade: 'Unicorn Hunter' }],
  [4, { coins: 175_000, tickets: 40, grade: 'Blockchain Gurur' }],
  [5, { coins: 150_000, tickets: 35, grade: 'ICO Insider' }],
  [6, { coins: 125_000, tickets: 30, grade: 'Token Flipper' }],
  [7, { coins: 100_000, tickets: 25, grade: 'Yield Farmer' }],
  [8, { coins: 75_000, tickets: 20, grade: 'HODLer' }],
  [9, { coins: 50_000, tickets: 15, grade: 'Moon Trader' }],
  [10, { coins: 20_000, tickets: 10, grade: 'Noob' }],
]);

export const NFT_REWARDS_HERE = { coins: 125_000, tickets: 30 };

export const NFT_REWARDS_HAPI = { coins: 20_000, tickets: 10 };

export const NFT_REWARDS_USMEN = new Map<number, { coins: number; tickets: number; grade: string }>(
  [
    [1, { coins: 50_000, tickets: 50, grade: 'Rare' }],
    [2, { coins: 5_000, tickets: 5, grade: 'Common' }],
  ],
);

export const NFT_REWARDS_DD = new Map<number, { coins: number; tickets: number; grade: string }>([
  [1, { coins: 30_000, tickets: 15, grade: 'Rare' }],
  [2, { coins: 20_000, tickets: 10, grade: 'Regular' }],
]);

export const NFT_REWARDS_YUP = new Map<number, { coins: number; tickets: number; grade: string }>([
  [1, { coins: 50_000, tickets: 18, grade: '0' }],
  [2, { coins: 45_000, tickets: 16, grade: '1' }],
  [3, { coins: 40_000, tickets: 14, grade: '2' }],
  [4, { coins: 35_000, tickets: 12, grade: '3' }],
  [5, { coins: 30_000, tickets: 10, grade: '4' }],
  [6, { coins: 25_000, tickets: 8, grade: 'Legendary' }],
  [7, { coins: 20_000, tickets: 6, grade: 'Epic' }],
  [8, { coins: 15_000, tickets: 4, grade: 'Rare' }],
  [9, { coins: 10_000, tickets: 2, grade: 'Uncommon' }],
  [10, { coins: 5_000, tickets: 1, grade: 'Common' }],
]);

export const NFT_REWARDS_USMEN_LEVEL_TRAIT = 'Background';

export const NFT_REWARDS_DD_LEVEL_TRAIT = 'Body';

export const NFT_LEVEL_TRAIT_TYPE = 'Torso';

export const PT_NFT_LEVEL_BY_GRADE = new Map<ENFT_GRADE, number>([
  [ENFT_GRADE.Whale, 1],
  [ENFT_GRADE.Shark, 2],
  [ENFT_GRADE.Unicorn, 3],
  [ENFT_GRADE.Guru, 4],
  [ENFT_GRADE.ICO, 5],
  [ENFT_GRADE.Flipper, 6],
  [ENFT_GRADE.Farmer, 7],
  [ENFT_GRADE.Holder, 8],
  [ENFT_GRADE.Moon, 9],
  [ENFT_GRADE.Noob, 10],
]);

export const PT_NFT_LEVEL_BY_GRADE_SKILL = new Map<ENFT_GRADE, number>([
  [ENFT_GRADE.Whale, 10],
  [ENFT_GRADE.Shark, 9],
  [ENFT_GRADE.Unicorn, 8],
  [ENFT_GRADE.Guru, 7],
  [ENFT_GRADE.ICO, 6],
  [ENFT_GRADE.Flipper, 5],
  [ENFT_GRADE.Farmer, 4],
  [ENFT_GRADE.Holder, 3],
  [ENFT_GRADE.Moon, 2],
  [ENFT_GRADE.Noob, 1],
]);

export const USMEN_NFT_LEVEL_BY_GRADE = new Map<ENFT_USMEN_GRADE, number>([
  [ENFT_USMEN_GRADE.Rare, 1],
  [ENFT_USMEN_GRADE.Common, 2],
]);

export const DD_NFT_LEVEL_BY_GRADE = new Map<ENFT_DD_GRADE, number>([
  [ENFT_DD_GRADE.Rare, 1],
  [ENFT_DD_GRADE.Regular, 2],
]);

export const YUP_NFT_LEVEL_BY_GRADE = new Map<ENFT_YUP_GRADE, number>([
  [ENFT_YUP_GRADE.Zero, 1],
  [ENFT_YUP_GRADE.One, 2],
  [ENFT_YUP_GRADE.Two, 3],
  [ENFT_YUP_GRADE.Three, 4],
  [ENFT_YUP_GRADE.Four, 5],
  [ENFT_YUP_GRADE.Legendary, 6],
  [ENFT_YUP_GRADE.Epic, 7],
  [ENFT_YUP_GRADE.Rare, 8],
  [ENFT_YUP_GRADE.Uncommon, 9],
  [ENFT_YUP_GRADE.Common, 10],
]);

export const NFT_MINING = new Map<ENFT_GRADE, { coins: number; tickets: number }>([
  [ENFT_GRADE.Whale, { coins: 600, tickets: 10 }],
  [ENFT_GRADE.Shark, { coins: 550, tickets: 9 }],
  [ENFT_GRADE.Unicorn, { coins: 500, tickets: 8 }],
  [ENFT_GRADE.Guru, { coins: 450, tickets: 7 }],
  [ENFT_GRADE.ICO, { coins: 400, tickets: 6 }],
  [ENFT_GRADE.Flipper, { coins: 350, tickets: 5 }],
  [ENFT_GRADE.Farmer, { coins: 300, tickets: 4 }],
  [ENFT_GRADE.Holder, { coins: 250, tickets: 3 }],
  [ENFT_GRADE.Moon, { coins: 200, tickets: 2 }],
  [ENFT_GRADE.Noob, { coins: 150, tickets: 1 }],
]);
