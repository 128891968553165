import { EPurchaseStatus } from 'interface/product';
import { showErrorToast, showSuccessToast } from './toastUtils';
import { WebApp } from 'global';

export const handlePurchaseStatus = async (status: EPurchaseStatus) => {
  if (status === EPurchaseStatus.COMPLETED) {
    showSuccessToast('Purchase completed');
  }
};

export const openPurchaseLink = (tg: WebApp | null, purchaseUrl: string) => {
  if (tg?.openInvoice) {
    tg.openInvoice(purchaseUrl);
  } else {
    showErrorToast('Telegram invoice is not supported on your device!');
  }
};
