import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import useNFTsStore from 'store/nfts.store';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import { formatClaimTime } from 'utils/time';
import { NFT_MINING } from 'constants/nft';
import { ENFT_GRADE } from 'interface/nft';
import useCountdown from 'hooks/useCountdown';
import useBalanceStore from 'store/balance.store';
import { showErrorToast, showSuccessToast } from 'utils/toastUtils';
import useFarmingStore from 'store/farming.store';
import useWeb3Store from 'store/web3.store';
import { claimNFT } from 'api/farming';
import { ENFTStakingInvalidatedReason } from 'interface/farming';
import useMainStore from 'store/main.store';

import './NFTStaking.scss';

const getInvalidatedReason = (reason: ENFTStakingInvalidatedReason): string => {
  switch (reason) {
    case ENFTStakingInvalidatedReason.STAKING_STARTED_BY_ANOTHER_USER:
      return 'Oops! Looks like NFT was used by another user.';
    case ENFTStakingInvalidatedReason.USER_DISCONNECTED_HOT_WALLET:
      return 'Your HOT wallet was disconnected and PitchTalk NFT staking was canceled.';
    default:
      return 'Staking was invalidated, by system.';
  }
};

const NFTStaking: React.FC = () => {
  const navigate = useNavigate();
  const { showModal } = useModalStore();
  const { addCoins, addTickets } = useBalanceStore();
  const nfts = useNFTsStore((state) => state.nfts);
  const isLoadingNFTs = useNFTsStore((state) => state.isLoadingNFT);
  const nearWallet = useWeb3Store((state) => state.nearWallet);
  const autoClaimAvailableUntil = useMainStore((state) => state?.user?.autoClaimAvailableUntil);

  const staking = useFarmingStore((state) => state.staking);
  const setStaking = useFarmingStore((state) => state.setStaking);
  const nftId = staking?.nftId;
  const nftGrade = staking?.nftGrade;
  const stakedNFT = nfts.get(nftId?.toString() || '');
  const mining = NFT_MINING.get(nftGrade || ENFT_GRADE.Noob);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const countdown = useCountdown(endDate);
  const isEnded = countdown <= 0;
  const isConnected = nearWallet?.accountId;
  const [isAutoClaim, setIsAutoClaim] = useState(false);

  const onStakingInfoClick = () => showModal(EModals.NFT_STAKING_INFO_MODAL);

  const onStake = () => {
    if (!isConnected) {
      navigate('/nft');
      return;
    }
    showModal(EModals.NFT_STAKING_SELECT_MODAL);
  };

  const onUnStake = () => showModal(EModals.NFT_UNSTAKE_MODAL);

  const onClaim = async () => {
    if (!isEnded || !mining || !staking) return;
    if (!nfts.get(staking?.nftId) || staking.invalidated) {
      if (staking.invalidated) {
        showErrorToast(getInvalidatedReason(staking.invalidatedReason));
      } else {
        showErrorToast(
          'Unable to retrieve NFT metadata. Ensure that you are still the owner of the NFT and try again, please.',
        );
      }
    } else {
      const response = await claimNFT(staking.nftId);
      if (response) {
        addCoins(response.rewards.coins);
        addTickets(response.rewards.tickets);
        setEndDate(response.staking.endTime);
        setStaking(response.staking);
        showSuccessToast('NFT staking claim success!');
      }
    }
  };

  const showInvalidMsg = () => {
    if (!nfts.get(staking?.nftId || '')) {
      showErrorToast(
        'Unable to retrieve NFT metadata. Ensure that you are still the owner of the NFT and try again, please.',
      );
      return;
    }
    if (staking?.invalidated) {
      showErrorToast(getInvalidatedReason(staking.invalidatedReason));
      return;
    }
  };

  useEffect(() => {
    if (staking?.nftId) {
      setEndDate(new Date(staking.endTime));
    }
  }, [staking?.nftId, staking?.endTime]);

  const isStakingInvalid = isLoadingNFTs
    ? false
    : staking?.invalidated || !nfts.get(staking?.nftId || '');

  const seconds = useCountdown(
    autoClaimAvailableUntil ? new Date(autoClaimAvailableUntil) : null,
    1000,
  );

  useEffect(() => {
    const isActive = seconds ? seconds > 0 : false;
    setIsAutoClaim(isActive);
  }, [seconds]);

  return (
    <div className="nft-staking">
      <h2 className="nft-staking-title">
        NFT Staking
        <button className="help-button" onClick={onStakingInfoClick}>
          <img src="images/help.png" alt="?" />
        </button>
      </h2>
      <div className="nft-staking-content">
        {staking?.nftId ? (
          <div
            className={classNames('nft-staking-item', {
              'nft-staking-item--invalid': isStakingInvalid,
            })}>
            <div className="nft-staking-item-info">
              {stakedNFT?.metadata.media ? (
                <img src={stakedNFT?.metadata.media} alt="NFT" />
              ) : (
                <img src="images/nft-not-found.png" alt="NFT Not Found" />
              )}
              <div className="nft-staking-item-info-meta">
                <p>#{staking?.nftId || '9999'}</p>
                <p className="item-reward item-reward--points">
                  <img src="images/point.png" alt="Points Reward" />
                  <span>{mining?.coins}</span>/ hour
                </p>
                <p className="item-reward item-reward--tickets">
                  <img src="images/ticket.svg" alt="Tickets Reward" />
                  <span>{mining?.tickets}</span>/ hour
                </p>
              </div>
            </div>
            <div className="nft-staking-item-actions">
              <Button color="grey" text="Unstake" onClick={onUnStake} />
              {isStakingInvalid ? (
                <Button color="red" text="Invalid" onClick={showInvalidMsg} />
              ) : (
                <Button
                  color="primary"
                  disabled={isAutoClaim ? false : !isEnded}
                  text={
                    isAutoClaim
                      ? 'AutoClaim In Proggress'
                      : isEnded
                      ? 'Claim'
                      : `Claim in ${formatClaimTime(countdown)}`
                  }
                  onClick={isAutoClaim ? () => {} : onClaim}
                />
              )}
            </div>
          </div>
        ) : (
          <Button
            color="grey"
            className="nft-staking-stake-button"
            text={
              <>
                <img src="images/add-icon.png" alt="+" />
              </>
            }
            onClick={onStake}
          />
        )}
      </div>
    </div>
  );
};

export default NFTStaking;
