import { sleep } from './time';

const imagesToPreload = [
  'images/leader-1.png',
  'images/leader-2.png',
  'images/leader-3.png',

  'images/daily-prize.gif',

  'images/ticket.svg',
  'images/point.png',
  'images/hot.png',

  'images/level/1.svg',

  'images/task/general-tg.svg',
  'images/task/general-x.svg',
  'images/task/general-youtube.svg',

  'images/arrow.svg',
  'images/check-icon.png',
  'images/close-icon.png',
  'images/copy-icon-inverted.png',
  'images/copy-icon.png',
  'images/copy-icon-success.png',
  'images/download-icon.svg',

  'images/task-completed-icon.png',
  'images/task-review-icon.png',
  'images/shadow.png',

  'images/toast-error-icon.png',
  'images/toast-success-icon.png',
  'images/warning.png',

  'images/help.png',

  'images/prize.svg',
  'images/shine.svg',
  'images/reload.png',
  'images/connect-hot-wallet.svg',
  'images/no-nfts-image.png',
];

const imagesToPreloadSnow = [
  '/images/snow-season/faq/1.png',
  '/images/snow-season/faq/2.png',
  '/images/snow-season/faq/3.png',
  '/images/snow-season/faq/4.png',
  '/images/snow-season/faq/5.png',
  '/images/snow-season/faq/faq.png',

  '/images/snow-season/ice/1.png',
  '/images/snow-season/ice/2.png',
  '/images/snow-season/ice/3.png',
  '/images/snow-season/ice/4.png',

  '/images/snow-season/battle/WIN.png',
  '/images/snow-season/battle/Victory.png',
  '/images/snow-season/battle/LOSE.png',
  '/images/snow-season/battle/Defeat.png',
  '/images/snow-season/battle/DRAW.png',
  '/images/snow-season/battle/Draws.png',

  '/images/snow-season/footer/back.png',
  '/images/snow-season/footer/footer-active.png',
  '/images/snow-season/footer/airdrop.png',
  '/images/snow-season/footer/airdrop-active.png',
  '/images/snow-season/footer/history.png',
  '/images/snow-season/footer/history-active.png',
  '/images/snow-season/footer/home.png',
  '/images/snow-season/footer/home-active.png',
  '/images/snow-season/footer/leaderboard.png',
  '/images/snow-season/footer/leaderboard-active.png',

  '/images/snow-season/user/bottom.png',
  '/images/snow-season/user/head.png',
  '/images/snow-season/user/left.png',
  '/images/snow-season/user/right.png',

  '/images/snow-season/frame/points.png',
  '/images/snow-season/frame/round.png',

  '/images/snow-season/battle-waiting.png',
  '/images/snow-season/battle-waiting-end.png',

  '/images/snow-season/snowflake.png',
  '/images/snow-season/energy.png',
  '/images/snow-season/gem.png',
  '/images/snow-season/star.png',
  '/images/snow-season/star-active.png',

  '/images/snow-season/shine.svg',
  '/images/snow-season/shine-2.svg',

  '/images/snow-season/info-icon.png',
  '/images/snow-season/info-icon-blue.png',

  '/images/snow-season/tutorial.png',
  '/images/snow-season/tutorial-container.png',
  '/images/snow-season/preview.svg',
  '/images/snow-season/no-history.png',
];

export const preloadImages = async () => {
  const loadImage = (src: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve();
      img.onerror = reject;
    });
  };

  try {
    await Promise.all(imagesToPreload.map((src) => loadImage(src)));
    console.log('All images preloaded');
  } catch (error) {
    console.error('Error preloading images:', error);
  }
};

export const preloadFonts = async () => {
  const fontClashRegular = new FontFace(
    'Clash Display Variable',
    `url(${require('../assets/fonts/ClashDisplay-Regular.otf')})`,
    { weight: '400' },
  );

  const fontClashMedium = new FontFace(
    'Clash Display Variable',
    `url(${require('../assets/fonts/ClashDisplay-Medium.otf')})`,
    { weight: '500' },
  );

  const fontClashSemibold = new FontFace(
    'Clash Display Variable',
    `url(${require('../assets/fonts/ClashDisplay-Semibold.otf')})`,
    { weight: '600' },
  );

  const fontClashBold = new FontFace(
    'Clash Display Variable',
    `url(${require('../assets/fonts/ClashDisplay-Bold.otf')})`,
    { weight: '700' },
  );

  try {
    await Promise.all([
      fontClashRegular.load(),
      fontClashMedium.load(),
      fontClashSemibold.load(),
      fontClashBold.load(),
    ]);
    console.log('All fonts preloaded');
  } catch (error) {
    console.error('Error preloading fonts:', error);
  }

  document.fonts.add(fontClashRegular);
  document.fonts.add(fontClashMedium);
  document.fonts.add(fontClashSemibold);
  document.fonts.add(fontClashBold);
};

export const preloadSnowImages = async () => {
  const loadImage = (src: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve();
      img.onerror = reject;
    });
  };

  try {
    await Promise.all(imagesToPreloadSnow.map((src) => loadImage(src)));
    await sleep(2000);
    console.log('All images preloaded');
  } catch (error) {
    console.error('Error preloading images:', error);
  }
};
