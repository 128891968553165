import { createSelector } from 'reselect';
import { ETaskStatus } from 'interface/task';
import { compareTasksByStatus } from 'utils/sortTasks';
import { ITasksStore } from './tasks.store';

const selectDailyTasks = (state: ITasksStore) => state.dailyTasks;

const selectPartnersTasks = (state: ITasksStore) => state.partnersTasks;

const selectGeneralTasks = (state: ITasksStore) => state.generalTasks;

export const getDailyTasksRender = createSelector([selectDailyTasks], (dailyTasks) =>
  Array.from(dailyTasks.values()).sort((a, b) => compareTasksByStatus(a.status, b.status)),
);

export const getPartnersTasksRender = createSelector([selectPartnersTasks], (partnersTasks) =>
  Array.from(partnersTasks.values()).sort((a, b) => compareTasksByStatus(a.status, b.status)),
);

export const getGeneralTasksRender = createSelector([selectGeneralTasks], (generalTasks) =>
  Array.from(generalTasks.values()).sort((a, b) => compareTasksByStatus(a.status, b.status)),
);

export const getGeneralTasksProgress = createSelector(
  [selectGeneralTasks],
  (generalTasks) =>
    Array.from(generalTasks.values()).filter(
      (task) =>
        task.status === ETaskStatus.COMPLETED_CLAIMED ||
        task.status === ETaskStatus.COMPLETED_NOT_CLAIMED,
    ).length,
);

export const getDailyTasksProgress = createSelector(
  [selectDailyTasks],
  (dailyTasks) =>
    Array.from(dailyTasks.values()).filter(
      (task) =>
        task.status === ETaskStatus.VERIFY_REQUESTED ||
        task.status === ETaskStatus.COMPLETED_CLAIMED ||
        task.status === ETaskStatus.COMPLETED_NOT_CLAIMED,
    ).length,
);

export const getPartnersTasksProgress = createSelector(
  [selectPartnersTasks],
  (partnersTasks) =>
    Array.from(partnersTasks.values()).filter(
      (task) =>
        task.status === ETaskStatus.VERIFY_REQUESTED ||
        task.status === ETaskStatus.COMPLETED_CLAIMED ||
        task.status === ETaskStatus.COMPLETED_NOT_CLAIMED,
    ).length,
);

export const isAllGeneralTasksCompleted = createSelector([selectGeneralTasks], (generalTasks) => {
  const tasks = Array.from(generalTasks.values());
  return (
    tasks.length > 0 &&
    tasks.every(
      (task) =>
        task.status === ETaskStatus.COMPLETED_CLAIMED ||
        task.status === ETaskStatus.COMPLETED_NOT_CLAIMED,
    )
  );
});

export const isAllPartnersTasksCompleted = createSelector(
  [selectPartnersTasks],
  (partnersTasks) => {
    const tasks = Array.from(partnersTasks.values());
    return (
      tasks.length > 0 &&
      tasks.every(
        (task) =>
          task.status === ETaskStatus.COMPLETED_CLAIMED ||
          task.status === ETaskStatus.COMPLETED_NOT_CLAIMED,
      )
    );
  },
);
