import { IConfig } from 'config';

export default {
  serverUrl: 'https://stage-be.pitchtalk.app',
  botAppUrl: 'https://t.me/stagebot123_bot/app',
  botName: 'stagebot123_bot',
  nftContractId: 'pitchtalk.hot.tg',
  hereContractId: 'nft.herewallet.near',
  hapiContractId: 'hapi-nearonaut.near',
  hapiScoreContractId: 'score-v1.hapiprotocol.near',
  usmenContractId: 'usmen.hot.tg',
  ddContractId: 'doubledog.hot.tg',
  yupContractId: 'darai.mintbase1.near',
  fittonAppUrl: 'https://t.me/fittonappstage_bot/app',
  meerkatAppUrl: 'https://t.me/meerkat_coin_bot/app',
  // fittonAppUrl: 'https://t.me/fittonappdev_bot/app',
} as unknown as IConfig;
