import { motion } from 'framer-motion';
import React from 'react';
import { defaultAnimation } from 'styles/animations';

import './Leaderboard.scss';

const Leaderboard: React.FC = () => {
  return (
    <motion.div className="snow-leaderboard-page" {...defaultAnimation}>
      <div className="snow-leaderboard-page-content">
        <h2>Leaderboard</h2>
        <p>Coming Soon</p>
      </div>
    </motion.div>
  );
};

export default Leaderboard;
