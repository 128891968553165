import { IConfig } from 'config';

export default {
  serverUrl: 'https://api.pitchtalk.app',
  botAppUrl: 'https://t.me/pitchtalk_bot/app',
  botName: 'pitchtalk_bot',
  nftContractId: 'pitchtalk.hot.tg',
  hereContractId: 'nft.herewallet.near',
  hapiContractId: 'hapi-nearonaut.near',
  hapiScoreContractId: 'score-v1.hapiprotocol.near',
  usmenContractId: 'usmen.hot.tg',
  ddContractId: 'doubledog.hot.tg',
  yupContractId: 'darai.mintbase1.near',
  fittonAppUrl: 'https://t.me/fittonapp_bot/app',
  meerkatAppUrl: 'https://t.me/meerkat_coin_bot/app',
} as unknown as IConfig;
