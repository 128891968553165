import React, { memo, useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import ProgressBar from 'components/ProgressBar';
import Tasks from 'components/Tasks';
import { getTaskIcon } from 'constants/tasks';
import { ETaskSlug, ETaskStatus, ITaskProgress } from 'interface/task';
import { formatNumber } from 'utils/formatNumber';
import Claim from 'components/Claim';
import useMainStore from 'store/main.store';
import useBalanceStore from 'store/balance.store';
import { verifyTasks, startBasicTask, createBasicTask, verifyTasksById } from 'api/tasks';
import { sleep } from 'utils/time';
import useTasksStore from 'store/tasks.store';
import Header from 'components/Header';
import {
  getGeneralTasksProgress,
  getGeneralTasksRender,
  isAllGeneralTasksCompleted,
} from 'store/tasks.selector';
import Boosts from 'components/Boosts';
import NFTStaking from 'components/NFTStaking';
import useFarmingStore from 'store/farming.store';
import AutoClaimButton from './components/AutoClaimButton';
import Button from 'components/Button';
import { preloadSnowImages } from 'utils/preload';
import useBattleStore from 'store/battle.store';

import './Home.scss';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const tg = useMainStore(({ tgWebApp }) => tgWebApp);
  const coins = useBalanceStore(({ coins }) => coins);
  const farming = useFarmingStore(({ farming }) => farming);
  const updateCoins = useBalanceStore(({ updateCoins }) => updateCoins);
  const updateTickets = useBalanceStore(({ updateTickets }) => updateTickets);
  const autoClaimAvailableUntil = useMainStore(({ user }) => user?.autoClaimAvailableUntil);
  const battleProfileId = useMainStore(({ user }) => user?.battleProfileId);
  const battleProfile = useBattleStore(({ profile }) => profile);
  const generalTasksRender = useTasksStore(getGeneralTasksRender);
  const generalTasksProgress = useTasksStore(getGeneralTasksProgress);
  const isGeneralCompleted = useTasksStore(isAllGeneralTasksCompleted);
  const updateGeneralTaskStatus = useTasksStore(
    ({ updateGeneralTaskStatus }) => updateGeneralTaskStatus,
  );
  const updateGeneralTaskStatusById = useTasksStore(
    ({ updateGeneralTaskStatusById }) => updateGeneralTaskStatusById,
  );
  const updateGeneralTask = useTasksStore(({ updateGeneralTask }) => updateGeneralTask);
  const setIsSnowLoading = useBattleStore((state) => state.setIsLoading);

  // TODO: Make hook
  const onGeneralTaskClick = async (task: ITaskProgress) => {
    if (task.template.slug === ETaskSlug.BASIC_BATTLE_ACCOUNT) {
      showAirdropAndPvPModal();
    } else {
      if (tg?.openLink) {
        if (
          task.template.slug === ETaskSlug.BASIC_TG ||
          task.template.slug === ETaskSlug.BASIC_CHAT
        ) {
          tg.openTelegramLink(task.template.url);
        } else {
          tg.openLink(task.template.url);
        }
      } else {
        window.open(task.template.url, '_blank', 'noopener noreferrer');
      }
    }
    let taskId = task.id;
    if (task.template.slug === ETaskSlug.BASIC_BATTLE_ACCOUNT && taskId === '1') {
      const taskResp = await createBasicTask(task.template.slug);
      if (taskResp) {
        taskId = taskResp.id;
        updateGeneralTask(task.template.slug, taskResp);
      }
    } else {
      await startBasicTask(taskId);
    }
    updateGeneralTaskStatus(task.template.slug, ETaskStatus.VERIFY_REQUESTED);
    if (task.template.slug === ETaskSlug.BASIC_BATTLE_ACCOUNT) {
      await sleep(3000);
      const resp = await verifyTasksById(taskId);
      if (resp) {
        resp.coins && updateCoins(resp.coins);
        resp.tickets && updateTickets(resp.tickets);
        updateGeneralTaskStatus(task.template.slug, resp.tasks.status);
      }
    } else {
      await sleep(5000);
      const resp = await verifyTasks();
      if (resp) {
        resp.coins && updateCoins(resp.coins);
        resp.tickets && updateTickets(resp.tickets);
        resp.tasks.forEach((task) => updateGeneralTaskStatusById(task.id, task.status));
      }
    }
  };

  useEffect(() => {
    const checkAndVerifyTasks = async () => {
      const hasPendingTasks = generalTasksRender.some(
        (task) => task.status === ETaskStatus.VERIFY_REQUESTED,
      );
      if (hasPendingTasks) {
        const resp = await verifyTasks();
        if (resp) {
          resp.coins && updateCoins(resp.coins);
          resp.tickets && updateTickets(resp.tickets);
          resp.tasks.forEach((task) => updateGeneralTaskStatusById(task.id, task.status));
        }
      }
    };

    checkAndVerifyTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalTasksProgress]);

  const isWithBoosts = !!farming;

  const showAirdropAndPvPModal = async () => {
    setIsSnowLoading(true);
    navigate(battleProfileId || battleProfile ? '/snow-season' : '/snow-season/tutorial');
    preloadSnowImages().then(() => setIsSnowLoading(false));
  };

  return (
    <motion.div
      className={classNames('home-page', { 'home-page--with-boosts': isWithBoosts })}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
      <Header />
      <div className="pitchtalk-points">
        <h4>
          <span>Your PitchTalk Points</span>
          <AutoClaimButton
            endDate={autoClaimAvailableUntil ? new Date(autoClaimAvailableUntil) : null}
          />
        </h4>
        <span>
          <b>{formatNumber(coins)}</b>
          <img src="images/point.png" alt="Points" />
        </span>
      </div>
      <ProgressBar />
      <Claim />
      <Button color="ice" text="AirDrop and PvP Battles" onClick={showAirdropAndPvPModal} />
      {isWithBoosts ? <Boosts /> : null}
      {!isGeneralCompleted ? (
        <Tasks
          label="General"
          completed={generalTasksProgress}
          className="general-tasks-list"
          isRow>
          {generalTasksRender.map((task, i) => (
            <li
              className={classNames(task.status ? task.status : '', task.template.slug)}
              key={i}
              onClick={() => onGeneralTaskClick(task)}>
              <img src={task.template.image} alt="" />
              <p className="task">{task.template.title}</p>
              {!!task.template.rewardTickets && (
                <p className="reward reward-tickets">
                  <img src="images/ticket.png" alt="Point" />
                  <span>+{formatNumber(task.template.rewardTickets)}</span>
                </p>
              )}
              <p className="reward">
                <img src="images/point.png" alt="Point" />
                <span>+{formatNumber(task.template.rewardCoins)}</span>
              </p>
              {task.status && task.status !== ETaskStatus.INITIAL && (
                <p className="status-icon">
                  <img src={getTaskIcon(task.status)} alt="" />
                </p>
              )}
            </li>
          ))}
        </Tasks>
      ) : (
        <NFTStaking />
      )}
    </motion.div>
  );
};

export default memo(Home);
