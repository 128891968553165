import { EBattleAttackResult, EBattleDefenseResult } from 'interface/battle';

export const getAttackPoints = (
  attackResult: EBattleAttackResult,
  defenseResult: EBattleDefenseResult,
): number => {
  switch (attackResult) {
    case EBattleAttackResult.SUCCESS:
      if (defenseResult === EBattleDefenseResult.LUCKY_DODGE) {
        return 2;
      }
      return 4;
    case EBattleAttackResult.LUCKY_HIT:
      return 2;
    default:
      if (defenseResult === EBattleDefenseResult.LUCKY_DODGE) {
        return 2;
      }
      return 0;
  }
};

export const getDefensePoints = (
  defenseResult: EBattleDefenseResult,
  attackResult: EBattleAttackResult,
): number => {
  switch (defenseResult) {
    case EBattleDefenseResult.SUCCESS:
      if (attackResult === EBattleAttackResult.LUCKY_HIT) {
        return 1;
      }
      return 2;
    case EBattleDefenseResult.LUCKY_DODGE:
      return 1;
    default:
      if (attackResult === EBattleAttackResult.LUCKY_HIT) {
        return 1;
      }
      return 0;
  }
};

export const isDefenseLuck = (result: EBattleDefenseResult) =>
  result === EBattleDefenseResult.LUCKY_DODGE;
export const isAttackLuck = (result: EBattleAttackResult) =>
  result === EBattleAttackResult.LUCKY_HIT;
