import { IRound } from 'interface/battle';
import React, { memo } from 'react';
import classNames from 'classnames';
import { getAttackPoints, getDefensePoints, isDefenseLuck, isAttackLuck } from 'utils/battle';

import './RoundDetails.scss';

interface IRoundDetails {
  round: IRound;
  isFull?: boolean;
}

const RoundDetails: React.FC<IRoundDetails> = ({ round, isFull = true }) => {
  const isPlayerWinner = round.playerScore > round.opponentScore;
  const isOpponentWinner = round.opponentScore > round.playerScore;

  const playerAttackPoints = getAttackPoints(round.playerAttackResult, round.opponentDefenseResult);
  const opponentAttackPoints = getAttackPoints(
    round.opponentAttackResult,
    round.playerDefenseResult,
  );
  const playerDefensePoints = getDefensePoints(
    round.playerDefenseResult,
    round.opponentAttackResult,
  );
  const opponentDefensePoints = getDefensePoints(
    round.opponentDefenseResult,
    round.playerAttackResult,
  );

  const luckImg = (
    <img
      className="round-details__info-item-score-luck"
      src="/images/snow-season/star-active.png"
      alt=""
    />
  );

  const defenseImg = (
    <img
      className="round-details__info-item--defense"
      src="/images/snow-season/battle/defense.png"
      alt=""
    />
  );
  const attackImg = (
    <img
      className="round-details__info-item--attack"
      src="/images/snow-season/battle/attack.png"
      alt=""
    />
  );

  return (
    <div className="round-details">
      {isFull && (
        <>
          <h2 className="round-details__number">Round {round.roundNumber}</h2>
          <div className="round-details__score">
            <p
              className={classNames('round-details__score-item', {
                'round-details__score-item-winner': isPlayerWinner,
              })}>
              {round.playerScore ? `+${round.playerScore}` : round.playerScore}
            </p>
            <p>:</p>
            <p
              className={classNames('round-details__score-item', {
                'round-details__score-item-winner': isOpponentWinner,
              })}>
              {round.opponentScore ? `+${round.opponentScore}` : round.opponentScore}
            </p>
          </div>
        </>
      )}
      <div className="round-details__info">
        <div className="round-details__info-item">
          <p className="round-details__info-item-action">
            {defenseImg}
            {round.playerDefenseTarget}
          </p>
          <p className="round-details__info-item-score">
            {isDefenseLuck(round.playerDefenseResult) && luckImg}
            <span>{playerDefensePoints}</span>
            <span>:</span>
            <span>{opponentAttackPoints}</span>
            {isAttackLuck(round.opponentAttackResult) && luckImg}
          </p>
          <p className="round-details__info-item-action">
            {round.opponentAttackTarget}
            {attackImg}
          </p>
        </div>
        <div className="round-details__info-item">
          <p className="round-details__info-item-action">
            {attackImg}
            {round.playerAttackTarget}
          </p>
          <p className="round-details__info-item-score">
            {isAttackLuck(round.playerAttackResult) && luckImg}
            <span>{playerAttackPoints}</span>
            <span>:</span>
            <span>{opponentDefensePoints}</span>
            {isDefenseLuck(round.opponentDefenseResult) && luckImg}
          </p>
          <p className="round-details__info-item-action">
            {round.opponentDefenseTarget}
            {defenseImg}
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(RoundDetails);
