import React, { memo } from 'react';
import { formatNumber } from 'utils/formatNumber';

import './Gems.scss';

interface IGemsProps {
  gems: number | undefined;
}

const Gems: React.FC<IGemsProps> = ({ gems = 0 }) => {
  return (
    <div className="gems-container">
      <img className="gems-container-icon" src="/images/snow-season/gem.png" alt="" />
      <span className="gems-container-amount">
        <span>{formatNumber(gems, 'short')}</span>
      </span>
    </div>
  );
};

export default memo(Gems);
