import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { defaultAnimation } from 'styles/animations';
import useBattleStore from 'store/battle.store';

import './SnowFooter.scss';

const SnowFooter: React.FC = () => {
  const location = useLocation();
  const { isLoading } = useBattleStore();
  const [isVisible, setIsVisible] = useState(true);

  const isHomeIconActive =
    location.pathname === '/snow-season' || location.pathname === '/snow-season/round';
  const isLeaderboardIconActive = location.pathname === '/snow-season/leaderboard';
  const isHistoryIconActive = location.pathname === '/snow-season/history';
  const isAirdropIconActive = location.pathname === '/snow-season/airdrop';
  const isTutorial = location.pathname === '/snow-season/tutorial';

  useEffect(() => {
    if (isTutorial) {
      setIsVisible(false);
    } else {
      setTimeout(() => setIsVisible(true), 500);
    }
  }, [isTutorial]);

  if (!isVisible || isLoading) return null;

  return (
    <motion.footer className="snow-footer" {...defaultAnimation}>
      <ul>
        <li className={isHomeIconActive ? 'active' : ''}>
          <NavLink to="/snow-season">
            <img
              src={`/images/snow-season/footer/home${isHomeIconActive ? '-active' : ''}.png`}
              alt="home"
            />
          </NavLink>
          {isHomeIconActive && <div className="active-indicator" />}
        </li>
        <li className={isLeaderboardIconActive ? 'active' : ''}>
          <NavLink to="/snow-season/leaderboard">
            <img
              src={`/images/snow-season/footer/leaderboard${
                isLeaderboardIconActive ? '-active' : ''
              }.png`}
              alt="leaderboard"
            />
          </NavLink>
          {isLeaderboardIconActive && <div className="active-indicator" />}
        </li>
        <li className={isHistoryIconActive ? 'active' : ''}>
          <NavLink to="/snow-season/history">
            <img
              src={`/images/snow-season/footer/history${isHistoryIconActive ? '-active' : ''}.png`}
              alt="History"
            />
          </NavLink>
          {isHistoryIconActive && <div className="active-indicator" />}
        </li>
        <li className={isAirdropIconActive ? 'active' : ''}>
          <NavLink to="/snow-season/airdrop">
            <img
              src={`/images/snow-season/footer/airdrop${isAirdropIconActive ? '-active' : ''}.png`}
              alt="airdrop"
            />
          </NavLink>
          {isAirdropIconActive && <div className="active-indicator" />}
        </li>
        <li>
          <NavLink to="/">
            <img src="/images/snow-season/footer/back.png" alt="back" />
          </NavLink>
        </li>
      </ul>
    </motion.footer>
  );
};

export default SnowFooter;
