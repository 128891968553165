import { PT_NFT_LEVEL_BY_GRADE_SKILL } from 'constants/nft';
import { useMemo } from 'react';
import useFarmingStore from 'store/farming.store';
import useMainStore from 'store/main.store';
import useNFTsStore from 'store/nfts.store';
import { isAllGeneralTasksCompleted, isAllPartnersTasksCompleted } from 'store/tasks.selector';
import useTasksStore from 'store/tasks.store';

export const useLuck = () => {
  const level = useMainStore((state) => state.user?.level);
  const hapiScore = useMainStore((state) => state.user?.hapiScore);
  const isGeneralCompleted = useTasksStore(isAllGeneralTasksCompleted);
  const isPartnersCompleted = useTasksStore(isAllPartnersTasksCompleted);
  const isAllTasksCompleted = isPartnersCompleted && isGeneralCompleted;

  const hapiNftsAmount = useNFTsStore((state) => state.hapiNfts.size);
  const ddNftsAmount = useNFTsStore((state) => state.ddNfts.size);
  const usmenNftsAmount = useNFTsStore((state) => state.usmenNfts.size);
  const yupNftsAmount = useNFTsStore((state) => state.yupNfts.size);

  const stakingNFTGrade = useFarmingStore((state) => state.staking?.nftGrade);
  const pitchTalkNftsLuck = stakingNFTGrade
    ? (PT_NFT_LEVEL_BY_GRADE_SKILL.get(stakingNFTGrade) || 0) * 0.5
    : 0;

  const calculateLuck = () => {
    const levelLuck = level ? level : 0;
    const hapiScoreLuck = hapiScore ? 3 : 0;
    const tasksLuck = isAllTasksCompleted ? 5 : 0;
    const partersNFTsLuck =
      (hapiNftsAmount ? 0.5 : 0) +
      (ddNftsAmount ? 0.5 : 0) +
      (usmenNftsAmount ? 0.5 : 0) +
      (yupNftsAmount ? 0.5 : 0);
    const updatedPitchTalkNftsLuck = pitchTalkNftsLuck >= 5 ? 5 : pitchTalkNftsLuck;
    return levelLuck + tasksLuck + hapiScoreLuck + partersNFTsLuck + updatedPitchTalkNftsLuck;
  };

  const luck = calculateLuck();
  const luckStars = Math.min(Math.floor(luck / 5), 5);

  const stars = useMemo(
    () => Array.from({ length: 5 }).map((_, index) => index < luckStars),
    [luckStars],
  );

  return {
    level,
    levelSkill: level ? level * 10 : 0,
    tasksSkill: isAllTasksCompleted ? 100 : 0,
    hapiScoreSkill: hapiScore ? 100 : 0,
    partnersNFTsSkill:
      (hapiNftsAmount ? 25 : 0) +
      (ddNftsAmount ? 25 : 0) +
      (usmenNftsAmount ? 25 : 0) +
      (yupNftsAmount ? 25 : 0),
    pitchTalkNftsSkill: Math.min(pitchTalkNftsLuck * 20, 100),
    stars,
  };
};
