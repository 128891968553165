import React from 'react';
import PlayerHead from '../PlayerHead/PlayerHead';

import './UserImg.scss';

interface IUserImgProps {
  level: number | undefined;
  size: 'small' | 'large';
}

const UserImg: React.FC<IUserImgProps> = ({ level = 1, size = 'small' }) => {
  return (
    <div className={`user-img-container ${size}`}>
      <PlayerHead level={level} />

      <img className="user-img-container__ice-1" src="/images/snow-season/user/head.png" alt="" />
      <img className="user-img-container__ice-2" src="/images/snow-season/user/left.png" alt="" />
      <img className="user-img-container__ice-3" src="/images/snow-season/user/right.png" alt="" />
      <img className="user-img-container__ice-4" src="/images/snow-season/user/bottom.png" alt="" />
    </div>
  );
};

export default UserImg;
