import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { defaultAnimation } from 'styles/animations';
import Button from 'components/Button/Button';
import Score from 'components/SnowSeason/Score';
import { getBattles } from 'api/battle';
import useSWRInfinite from 'swr/infinite';

import './History.scss';

const ITEMS_PER_PAGE = 10;

const getKey = (pageIndex: number, previousPageData: any[]) => {
  if (previousPageData && !previousPageData.length) return null;
  return `/battles?page=${pageIndex + 1}&limit=${ITEMS_PER_PAGE}`;
};

const History: React.FC = () => {
  const navigate = useNavigate();

  const { data, setSize, isValidating, isLoading } = useSWRInfinite(
    (pageIndex, previousPageData) => getKey(pageIndex, previousPageData),
    async (url) => {
      const battles = await getBattles(
        parseInt(url.split('page=')[1].split('&')[0]),
        ITEMS_PER_PAGE,
      );
      return battles;
    },
    { revalidateOnFocus: false, revalidateOnMount: true },
  );

  const battles = data ? data.flat() : [];
  const hasMore = data && data[data.length - 1]?.length === ITEMS_PER_PAGE;
  const isHistoryEmpty = !data?.length;

  return (
    <motion.div className="snow-history-page" {...defaultAnimation}>
      <div className="snow-history-page-title">
        <h2>History</h2>
        <p>Relive your greatest snowball fights</p>
      </div>
      <div
        className={classNames('snow-history-page-content', {
          'snow-history-page-content--empty': isHistoryEmpty,
          'snow-history-page-content--loading': isLoading,
        })}>
        {isLoading ? (
          <div className="snow-history-page-content-loading">
            <motion.div className="loading-screen snow" {...defaultAnimation}>
              <img className="loading-img" src="/images/loading-snow.gif" alt="" />
              <h2>Loading...</h2>
            </motion.div>
          </div>
        ) : !isHistoryEmpty ? (
          battles.map((battle) => (
            <div key={battle.id} className="snow-history-page-content-item">
              <Score
                playerLevel={battle?.player?.level || 1}
                opponentLevel={battle?.opponent?.level || 1}
                playerName={battle?.player?.username}
                opponentName={battle?.opponent?.username}
                playerPoints={battle?.playerScore || 0}
                opponentPoints={battle?.opponentScore || 0}
              />
              <img
                className="snow-history-page-content-item__ice-1"
                src="/images/snow-season/ice/2.png"
                alt=""
              />
              <img
                className="snow-history-page-content-item__ice-2"
                src="/images/snow-season/ice/2.png"
                alt=""
              />
            </div>
          ))
        ) : (
          <div className="snow-history-page-content-empty">
            <img src="/images/snow-season/no-history.png" alt="No History" />
            <h2>No Matches Yet</h2>
            <Button
              color="ice-blue-2"
              text="Start Playing"
              onClick={() => navigate('/snow-season')}
            />
          </div>
        )}
        {hasMore && !isLoading && !isHistoryEmpty && (
          <div className="snow-history-page-content-load-more">
            <Button
              color="ice-blue-2"
              text="Load More"
              disabled={isValidating}
              onClick={() => setSize((prevSize) => prevSize + 1)}
            />
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default History;
