import { motion } from 'framer-motion';
import React, { memo } from 'react';

import './Stars.scss';

interface IStarsProps {
  stars: boolean[];
  isAnimate: boolean;
}

const Stars: React.FC<IStarsProps> = ({ stars, isAnimate }) => {
  const getAnimations = (active: boolean, index: number) =>
    isAnimate
      ? {
          initial: { opacity: 0.7, scale: 0.75 },
          animate: {
            opacity: active ? 1 : 0.75,
            scale: active ? [1, 1.15, 0.95, 1.05] : 1,
          },
          transition: {
            duration: active ? 0.8 : 0.3,
            ease: active ? [0.42, 0, 0.58, 1] : 'easeOut',
            delay: active ? index * 0.2 : 0,
          },
        }
      : {};

  return (
    <div className="stars-container">
      {stars.map((active, index) => (
        <motion.img
          key={index}
          className="star-item"
          src={active ? '/images/snow-season/star-active.png' : '/images/snow-season/star.png'}
          alt=""
          {...getAnimations(active, index)}
        />
      ))}
    </div>
  );
};

export default memo(Stars);
