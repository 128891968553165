import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BattleInfo from 'components/SnowSeason/BattleInfo';
import Round from 'components/SnowSeason/Round';
import {
  EBattleAttackResult,
  EBattleDefenseResult,
  EBattleTarget,
  ERoundResult,
  IRound,
} from 'interface/battle';
import { sleep } from 'utils/time';
import useBattleStore from 'store/battle.store';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import { createBattleRound } from 'api/battle';

import './RoundPage.scss';

export type RoundType = {
  playerPoints: number;
  opponentPoints: number;
  isCurrent: boolean;
  isFinishedAnimations: boolean;
  isNextRoundButton: boolean;
  isPlayerLuck: boolean;
  isOpponentLuck: boolean;
  isShowDetails: boolean;
  result: ERoundResult | null;
  details: IRound | null;
};

const NEW_ROUND: RoundType = {
  playerPoints: 0,
  opponentPoints: 0,
  isCurrent: true,
  isFinishedAnimations: false,
  isNextRoundButton: false,
  isPlayerLuck: false,
  isOpponentLuck: false,
  isShowDetails: false,
  result: null,
  details: null,
};

const RoundPage = () => {
  const navigate = useNavigate();
  const { showModal } = useModalStore();
  const battle = useBattleStore((state) => state.battle);
  const [currentRound, setCurrentRound] = useState(1);
  const [defenseTarget, setDefenseTarget] = useState<EBattleTarget | null>(null);
  const [attackTarget, setAttackTarget] = useState<EBattleTarget | null>(null);
  const [battleInfo, setBattleInfo] = useState<Record<number, RoundType>>({ 1: NEW_ROUND });
  const [isSnowballAnimation, setIsSnowballAnimation] = useState(false);
  const [isLuckAnimation, setIsLuckAnimation] = useState(false);
  const [isCountdown, setIsCountdown] = useState(false);

  const playerLevel = battle?.player.level || 1;
  const opponentLevel = battle?.opponent.level || 1;
  const playerName = battle?.player?.username;
  const opponentName = battle?.opponent?.username;

  const selectDefense = (target: EBattleTarget) => setDefenseTarget(target);

  const selectAttack = (target: EBattleTarget) => setAttackTarget(target);

  const onStartRound = async () => {
    if (!battle || !attackTarget || !defenseTarget) return;

    setDefenseTarget(null);
    setAttackTarget(null);

    const result = await createBattleRound(battle.id, attackTarget, defenseTarget);
    const currentRoundInfo = result?.rounds[currentRound - 1];

    if (!currentRoundInfo) return;

    const playerRoundPoints = currentRoundInfo.playerScore || 0;
    const opponentRoundPoints = currentRoundInfo.opponentScore || 0;

    const playerLuck =
      currentRoundInfo.playerAttackResult === EBattleAttackResult.LUCKY_HIT ||
      currentRoundInfo.playerDefenseResult === EBattleDefenseResult.LUCKY_DODGE;
    const opponentLuck =
      currentRoundInfo.opponentAttackResult === EBattleAttackResult.LUCKY_HIT ||
      currentRoundInfo.opponentDefenseResult === EBattleDefenseResult.LUCKY_DODGE;

    const isLuck = playerLuck || opponentLuck;

    setBattleInfo((p) => ({
      ...p,
      [currentRound]: {
        ...p[currentRound],
        isPlayerLuck: playerLuck,
        isOpponentLuck: opponentLuck,
      },
    }));

    setIsCountdown(true);
    await sleep(2000);
    setIsCountdown(false);

    setIsSnowballAnimation(true);
    if (isLuck) {
      await sleep(600);
      setIsLuckAnimation(true);
      await sleep(400);
      setBattleInfo((p) => ({
        ...p,
        [currentRound]: {
          ...p[currentRound],
          details: currentRoundInfo,
          isShowDetails: true,
        },
      }));
      await sleep(600);
      setIsLuckAnimation(false);
      await sleep(2300);
    } else {
      await sleep(1000);
      setBattleInfo((p) => ({
        ...p,
        [currentRound]: {
          ...p[currentRound],
          details: currentRoundInfo,
          isShowDetails: true,
        },
      }));
      await sleep(4100);
    }
    setIsSnowballAnimation(false);

    await sleep(2000);

    setBattleInfo((p) => ({
      ...p,
      [currentRound]: {
        playerPoints: playerRoundPoints,
        opponentPoints: opponentRoundPoints,
        isCurrent: true,
        isFinishedAnimations: true,
        isNextRoundButton: true,
        isPlayerLuck: playerLuck,
        isOpponentLuck: opponentLuck,
        details: currentRoundInfo,
        isShowDetails: false,
        result:
          playerRoundPoints > opponentRoundPoints
            ? ERoundResult.WIN
            : playerRoundPoints < opponentRoundPoints
            ? ERoundResult.LOSE
            : ERoundResult.DRAW,
      },
    }));

    setTimeout(() => {
      if (currentRound === 5) {
        setTimeout(() => {
          showModal(EModals.BATTLE_RESULT_MODAL, { battle: result });
          navigate('/snow-season/history');
        }, 2000);
      }
    }, 500);
  };

  const onNextRoundClick = () => {
    setBattleInfo((p) => ({
      ...p,
      [currentRound]: {
        ...p[currentRound],
        isCurrent: false,
      },
      [currentRound + 1]: NEW_ROUND,
    }));
    setCurrentRound((p) => p + 1);
  };

  const isBattleFinished = Object.keys(battleInfo).length === 6;

  const playerPoints = Object.values(battleInfo).reduce(
    (acc, round) => (acc += round.playerPoints),
    0,
  );
  const opponentPoints = Object.values(battleInfo).reduce(
    (acc, round) => (acc += round.opponentPoints),
    0,
  );

  useEffect(() => {
    if (battle?.rounds?.length) {
      setCurrentRound(battle.rounds.length + 1);

      const initialBattleInfo = battle.rounds.reduce(
        (acc: Record<number, RoundType>, round, index) => {
          const isPlayerLuck =
            round.playerAttackResult === EBattleAttackResult.LUCKY_HIT ||
            round.playerDefenseResult === EBattleDefenseResult.LUCKY_DODGE;
          const isOpponentLuck =
            round.opponentAttackResult === EBattleAttackResult.LUCKY_HIT ||
            round.opponentDefenseResult === EBattleDefenseResult.LUCKY_DODGE;

          const result =
            round.playerScore > round.opponentScore
              ? ERoundResult.WIN
              : round.playerScore < round.opponentScore
              ? ERoundResult.LOSE
              : ERoundResult.DRAW;

          acc[index + 1] = {
            playerPoints: round.playerScore || 0,
            opponentPoints: round.opponentScore || 0,
            isCurrent: false,
            isFinishedAnimations: true,
            isNextRoundButton: false,
            isShowDetails: false,
            details: round,
            isPlayerLuck,
            isOpponentLuck,
            result,
          };
          return acc;
        },
        {},
      );

      initialBattleInfo[battle.rounds.length + 1] = NEW_ROUND;

      setBattleInfo(initialBattleInfo);
    }

    if (!battle) {
      navigate('/snow-season');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="round-page">
      <BattleInfo
        playerLevel={playerLevel}
        opponentLevel={opponentLevel}
        playerName={playerName}
        opponentName={opponentName}
        playerPoints={playerPoints}
        opponentPoints={opponentPoints}
      />
      <div className="round-page__rounds">
        {Object.entries(battleInfo)
          .sort(([a], [b]) => Number(b) - Number(a))
          .map(([key, round]) => (
            <Round
              key={round.isCurrent ? 'current' : `previous-${key}`}
              id={Number(key)}
              round={round}
              isDisabled={!defenseTarget || !attackTarget}
              attackTarget={round.isCurrent ? attackTarget : null}
              defenseTarget={round.isCurrent ? defenseTarget : null}
              onStartRound={round.isCurrent ? onStartRound : () => {}}
              onNextRound={round.isCurrent ? onNextRoundClick : () => {}}
              selectDefense={
                isCountdown || isSnowballAnimation || round.isShowDetails
                  ? () => {}
                  : round.isCurrent
                  ? selectDefense
                  : () => {}
              }
              selectAttack={
                isCountdown || isSnowballAnimation || round.isShowDetails
                  ? () => {}
                  : round.isCurrent
                  ? selectAttack
                  : () => {}
              }
              playerLevel={playerLevel}
              opponentLevel={opponentLevel}
              isSnowballAnimation={isSnowballAnimation}
              isLuckAnimation={isLuckAnimation}
              isBattleFinished={isBattleFinished}
              isCountdown={round.isCurrent ? isCountdown : false}
            />
          ))}
      </div>
    </div>
  );
};

export default memo(RoundPage);
