export const MAX_SPEED_BOOST_LEVEL = 20;
export const MAX_TIME_BOOST_LEVEL = 19;

export enum EBoostType {
  SPEED = 'speed',
  TIME = 'time',
}

export interface SpeedBoostLevel {
  cost: number;
  miningSpeed: number;
  ticketsMiningSpeed: number;
  stars: number;
}

export interface TimeBoostLevel {
  cost: number;
  miningTime: number;
  stars: number;
}

export const SPEED_BOOST = new Map<number, SpeedBoostLevel>([
  [1, { cost: 0, miningSpeed: 150, ticketsMiningSpeed: 0, stars: 0 }],
  [2, { cost: 1000, miningSpeed: 180, ticketsMiningSpeed: 1, stars: 0 }],
  [3, { cost: 3000, miningSpeed: 210, ticketsMiningSpeed: 1, stars: 0 }],
  [4, { cost: 6000, miningSpeed: 240, ticketsMiningSpeed: 2, stars: 0 }],
  [5, { cost: 12000, miningSpeed: 270, ticketsMiningSpeed: 2, stars: 0 }],
  [6, { cost: 24000, miningSpeed: 300, ticketsMiningSpeed: 3, stars: 10 }],
  [7, { cost: 48000, miningSpeed: 330, ticketsMiningSpeed: 3, stars: 15 }],
  [8, { cost: 96000, miningSpeed: 360, ticketsMiningSpeed: 4, stars: 20 }],
  [9, { cost: 144000, miningSpeed: 390, ticketsMiningSpeed: 4, stars: 25 }],
  [10, { cost: 216000, miningSpeed: 420, ticketsMiningSpeed: 5, stars: 30 }],
  [11, { cost: 324000, miningSpeed: 450, ticketsMiningSpeed: 5, stars: 35 }],
  [12, { cost: 486000, miningSpeed: 480, ticketsMiningSpeed: 6, stars: 40 }],
  [13, { cost: 729000, miningSpeed: 510, ticketsMiningSpeed: 6, stars: 45 }],
  [14, { cost: 1093500, miningSpeed: 540, ticketsMiningSpeed: 7, stars: 50 }],
  [15, { cost: 1640250, miningSpeed: 570, ticketsMiningSpeed: 7, stars: 55 }],
  [16, { cost: 2460375, miningSpeed: 600, ticketsMiningSpeed: 8, stars: 60 }],
  [17, { cost: 3690563, miningSpeed: 630, ticketsMiningSpeed: 8, stars: 65 }],
  [18, { cost: 5535844, miningSpeed: 660, ticketsMiningSpeed: 9, stars: 70 }],
  [19, { cost: 8303766, miningSpeed: 690, ticketsMiningSpeed: 9, stars: 75 }],
  [20, { cost: 12455648, miningSpeed: 720, ticketsMiningSpeed: 10, stars: 80 }],
]);

export const TIME_BOOST = new Map<number, TimeBoostLevel>([
  [1, { cost: 0, miningTime: 6, stars: 0 }],
  [2, { cost: 3000, miningTime: 7, stars: 0 }],
  [3, { cost: 6000, miningTime: 8, stars: 0 }],
  [4, { cost: 12000, miningTime: 9, stars: 0 }],
  [5, { cost: 24000, miningTime: 10, stars: 0 }],
  [6, { cost: 48000, miningTime: 11, stars: 10 }],
  [7, { cost: 96000, miningTime: 12, stars: 15 }],
  [8, { cost: 192000, miningTime: 13, stars: 20 }],
  [9, { cost: 384000, miningTime: 14, stars: 25 }],
  [10, { cost: 768000, miningTime: 15, stars: 30 }],
  [11, { cost: 1536000, miningTime: 16, stars: 35 }],
  [12, { cost: 3072000, miningTime: 17, stars: 40 }],
  [13, { cost: 6144000, miningTime: 18, stars: 45 }],
  [14, { cost: 12288000, miningTime: 19, stars: 50 }],
  [15, { cost: 24576000, miningTime: 20, stars: 55 }],
  [16, { cost: 49152000, miningTime: 21, stars: 60 }],
  [17, { cost: 98304000, miningTime: 22, stars: 65 }],
  [18, { cost: 196608000, miningTime: 23, stars: 70 }],
  [19, { cost: 393216000, miningTime: 24, stars: 75 }],
]);
