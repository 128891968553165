import React, { memo } from 'react';
import PlayerHead from '../PlayerHead';
import OpponentHead from '../OpponentHead';
import useMainStore from 'store/main.store';

import './Score.scss';

const defenseImg = (
  <img
    className="score-info__player--defense"
    src="/images/snow-season/battle/defense.png"
    alt=""
  />
);
const attackImg = (
  <img className="score-info__player--attack" src="/images/snow-season/battle/attack.png" alt="" />
);

interface IScoreProps {
  playerLevel: number;
  opponentLevel: number;
  playerName: string;
  opponentName: string;
  playerPoints: number;
  opponentPoints: number;
  isIcons?: boolean;
}

const Score: React.FC<IScoreProps> = ({
  playerLevel,
  opponentLevel,
  playerName,
  opponentName,
  playerPoints,
  opponentPoints,
  isIcons = true,
}) => {
  const currentPlayerName = useMainStore((state) => state.user?.username);

  const isPlayerAttack = playerName === currentPlayerName;
  const isPlayerDefense = opponentName === currentPlayerName;

  return (
    <div className="score-container">
      <div className="score-info__player">
        <PlayerHead level={playerLevel} />
        <p>{playerName}</p>
      </div>
      <p className="score-info__score">
        {isPlayerAttack && attackImg}
        <span>{playerPoints}</span>
        <span>:</span>
        <span>{opponentPoints}</span>
        {isPlayerDefense && defenseImg}
      </p>
      <div className="score-info__player">
        <p>{opponentName}</p>
        <OpponentHead level={opponentLevel} />
      </div>
    </div>
  );
};

export default memo(Score);
