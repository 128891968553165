import React from 'react';
import classNames from 'classnames';
import useCountdown from 'hooks/useCountdown';
import { formatAutoClaimTime } from 'utils/time';
import { EModals } from 'interface/modals';
import useModalStore from 'store/modals.store';

const AutoClaimButton: React.FC<{ endDate: Date | null }> = ({ endDate }) => {
  const { showModal } = useModalStore();
  const seconds = useCountdown(endDate);
  const isAutoClaim = endDate ? endDate > new Date() : false;

  const onOpenAutoClaimModal = () => {
    showModal(EModals.AUTO_CLAIM, { endDate });
  };

  return (
    <button className="auto-claim-button" onClick={onOpenAutoClaimModal}>
      <div
        className={classNames('auto-claim-button__status', {
          'auto-claim-button__status--active': isAutoClaim,
        })}
      />
      {isAutoClaim ? formatAutoClaimTime(seconds) : 'Auto'}
    </button>
  );
};

export default AutoClaimButton;
