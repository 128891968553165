import { useEffect } from 'react';
import { EPurchaseStatus } from 'interface/product';
import { handlePurchaseStatus } from 'utils/purchase';
import useMainStore from 'store/main.store';
import { sleep } from 'utils/time';
import { getPurchase } from 'api/products';
import useBattleStore from 'store/battle.store';
import { getBattleProfile } from 'api/battle';

export const useBattleEnergyPurchase = (purchaseId?: string) => {
  const tg = useMainStore((state) => state.tgWebApp);

  const setProfile = useBattleStore((state) => state.setProfile);

  useEffect(() => {
    const fetchPurchase = async ({
      status,
    }: {
      url: string;
      status: 'paid' | 'cancelled' | 'failed' | 'pending';
    }) => {
      if (!purchaseId || status !== 'paid') return;
      await sleep(1000);
      const purchase = await getPurchase(purchaseId);
      if (purchase) {
        handlePurchaseStatus(purchase.status);
        if (purchase.status === EPurchaseStatus.COMPLETED) {
          const user = await getBattleProfile();
          if (user) {
            setProfile(user);
          }
        }
      }
    };

    tg?.onEvent('invoiceClosed', (args) => fetchPurchase(args));
    return () => {
      tg?.offEvent('invoiceClosed', (args) => fetchPurchase(args));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseId]);
};
