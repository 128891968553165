import { motion } from 'framer-motion';
import { defaultAnimation } from 'styles/animations';
import Button from 'components/Button';
import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useBattleStore from 'store/battle.store';
import SeasonInfo from 'components/SnowSeason/SeasonInfo/SeasonInfo';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import Header from 'components/SnowSeason/Header/Header';
import { getCurrentBattle, getBattle } from 'api/battle';

import './SnowSeason.scss';

const SnowSeason: React.FC = () => {
  const navigate = useNavigate();
  const { showModal } = useModalStore();
  const { profile, setBattle, isRefilling } = useBattleStore();
  const [battleId, setBattleId] = useState<string | null>(null);

  const startBattle = async () => {
    const battle = await getCurrentBattle();
    const currentBattleId = battle?.id?.toString();

    if (!currentBattleId) {
      showModal(EModals.BATTLE_WAITING_MODAL, {
        navigate,
        isCloseHidden: true,
      });
    } else {
      const currentBattle = await getBattle(currentBattleId);
      if (currentBattle) {
        setBattle(currentBattle);
        navigate('/snow-season/round');
      }
    }
  };

  const isDisabled = isRefilling ? true : profile ? profile?.energy === 0 : true;

  useEffect(() => {
    (async () => {
      const currentBattle = await getCurrentBattle();
      if (currentBattle) {
        setBattleId(currentBattle.id.toString());
      }
    })();
  }, []);

  return (
    <motion.div className="snow-season-page" {...defaultAnimation}>
      <Header />
      <SeasonInfo />
      <img
        className="snow-season-page__preview"
        src="images/snow-season/preview.svg"
        alt="Snow season"
      />
      <Button
        className="snow-season-page__button"
        color="ice-blue"
        text={
          isRefilling
            ? 'Making Snowballs...'
            : isDisabled && !battleId
            ? 'No energy...'
            : battleId
            ? 'Continue battle'
            : 'Find an Opponent'
        }
        onClick={() => startBattle()}
        disabled={isDisabled}
      />
    </motion.div>
  );
};

export default memo(SnowSeason);
