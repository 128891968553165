import React, { memo } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import UserImg from 'components/SnowSeason/UserImg';
import useMainStore from 'store/main.store';
import Gems from 'components/SnowSeason/Gems';
import useBattleStore from 'store/battle.store';
import SeasonInfo from 'components/SnowSeason/SeasonInfo';
import Stars from 'components/SnowSeason/Stars';
import { useLuck } from 'hooks/snow/useLuck';
import { defaultAnimation } from 'styles/animations';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';

import './Airdrop.scss';

const Airdrop: React.FC = () => {
  const navigate = useNavigate();
  const showModal = useModalStore((state) => state.showModal);
  const username = useMainStore((state) => state.user?.username);
  const level = useMainStore((state) => state.user?.level);
  const gems = useBattleStore((state) => state.profile?.score);
  const { stars } = useLuck();

  const handleShowFAQ = () => {
    showModal(EModals.SNOW_FAQ_MODAL);
  };

  const handleShowTutorial = () => {
    navigate('/snow-season/tutorial?step=2');
  };

  return (
    <motion.div className="airdrop-page" {...defaultAnimation}>
      <div className="airdrop-page-user">
        <UserImg size="large" level={level} />
        <h3>{username}</h3>
      </div>
      <div className="airdrop-page-gems">
        <h3 className="title-text">
          Airdrop Gems
          <img
            onClick={handleShowFAQ}
            className="info-icon"
            src="/images/snow-season/info-icon-blue.png"
            alt="i"
          />
        </h3>
        <Gems gems={gems} />
      </div>
      <div className="airdrop-page-luck">
        <h3 className="title-text">
          Snowy Luck
          <img
            onClick={handleShowTutorial}
            className="info-icon"
            src="/images/snow-season/info-icon-blue.png"
            alt="i"
          />
        </h3>
        <Stars stars={stars} isAnimate={false} />
        <p className="luck-text">You can boost it as you play!</p>
      </div>
      <SeasonInfo />
    </motion.div>
  );
};

export default memo(Airdrop);
