import React, { useEffect, useRef, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import useSWR, { useSWRConfig } from 'swr';
import { motion } from 'framer-motion';
import Button from 'components/Button';
import User from 'components/User';
import { formatNumber } from 'utils/formatNumber';
import useMainStore from 'store/main.store';
import { BOT_LINK, REFERRAL_BONUS, REFERRAL_BONUS_PERCENT } from 'constants/main';
import { claimReferralsReward, fetchReferrals, fetchReferralsCount } from 'api/referals';
import useBalanceStore from 'store/balance.store';
import { getUser } from 'api/user';

import './Referrals.scss';

const ITEMS_PER_PAGE = 10;

const getKey = (pageIndex: number, previousPageData: any[]) => {
  if (previousPageData && !previousPageData.length) return null;
  return `/referrals?page=${pageIndex + 1}&limit=${ITEMS_PER_PAGE}`;
};

const Referrals: React.FC = () => {
  const pageRef = useRef<HTMLDivElement | null>(null);
  const user = useMainStore((state) => state.user);
  const referralRewards = useBalanceStore((state) => state.referralRewards);
  const updateCoins = useBalanceStore((state) => state.updateCoins);
  const updateReferralRewards = useBalanceStore((state) => state.updateReferralRewards);
  const [isCopied, setIsCopied] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const { mutate } = useSWRConfig();

  const { data: referralsCount } = useSWR('/referralsCount', () => fetchReferralsCount(), {
    revalidateOnFocus: false,
    revalidateOnMount: true,
  });
  const { data, error, setSize, isValidating } = useSWRInfinite(
    (pageIndex, previousPageData) => getKey(pageIndex, previousPageData),
    (url) => fetchReferrals(parseInt(url.split('page=')[1].split('&')[0]), ITEMS_PER_PAGE),
    { revalidateOnFocus: false, revalidateOnMount: true },
  );

  const referrals = data ? data.flat() : [];
  const isLoading = isValidating || (!data && !error);
  const hasMore = data && data[data.length - 1]?.length === ITEMS_PER_PAGE;

  const onClaim = async () => {
    setIsClaiming(true);
    await claimReferralsReward()
      .then((newCoins) => {
        updateCoins(newCoins);
        updateReferralRewards(0);
        mutate('/user-rank');
      })
      .finally(() => setIsClaiming(false));
  };

  const onLinkCopy = () => {
    const link = `${BOT_LINK}?startapp=${user?.referralCode}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((err) => console.error('Failed to copy: ', err));
  };

  useEffect(() => {
    (async () => {
      const user = await getUser();
      if (user) {
        updateReferralRewards(user.referralRewards);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralsCount]);

  return (
    <motion.div
      className="referrals-page"
      ref={pageRef}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
      <div className="referrals-page-header">
        <h3>Your reward:</h3>
        <p className="rewards-amount">
          <b>{formatNumber(referralRewards)}</b>
          <img src="images/point.png" alt="Points" />
        </p>
        <Button
          color="primary"
          text={isClaiming ? 'Claiming' : 'Claim'}
          onClick={onClaim}
          disabled={isClaiming || !referralRewards}
        />
        <p className="rewards-info">
          For each friend you invite, you receive
          <br />
          <span>+{REFERRAL_BONUS}</span> <span>PitchTalk Points</span>
          <br />
          <span className="cashback">
            +{REFERRAL_BONUS_PERCENT * 100}% cashback on you friends's claims
          </span>
        </p>
      </div>
      <Button
        className="copy-link-button"
        color={isCopied ? 'grey' : 'white'}
        text={
          <>
            <span>{isCopied ? 'Copied' : 'Copy invite link'}</span>
            <img src={`images/copy-icon${isCopied ? '-success' : ''}.png`} alt="" />
          </>
        }
        onClick={onLinkCopy}
      />
      <div className="referrals-user-count">
        <h4>Your Referrals</h4>
        <b>{formatNumber(referralsCount)}</b>
      </div>
      <ul>
        {referrals.map((referral, i) => (
          <li key={i}>
            <User username={referral.referralUserName} />
            <p className="referrals-bonus">
              <img src="images/point.png" alt="Points" />+{referral.totalEarned}
            </p>
          </li>
        ))}
        {isLoading && (
          <li className="list-loading">
            <div className="loading-circle">
              <div className="loading-inner" />
            </div>
          </li>
        )}
        {!hasMore && !isLoading && (
          <p className="no-items">
            {!referrals.length ? 'No referrals yet.' : 'No more referrals.'}
          </p>
        )}
      </ul>
      {hasMore && !isLoading && (
        <Button
          color="white"
          className="load-more-btn"
          text="Load more"
          onClick={() => setSize((prevSize) => prevSize + 1)}
        />
      )}
    </motion.div>
  );
};

export default Referrals;
