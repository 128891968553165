import classNames from 'classnames';
import Button from 'components/Button';
import React, { memo } from 'react';

interface IRoundButtonProps {
  isNextRoundButton?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  onNextRound?: () => void;
  onStartRound?: () => void;
}

const RoundButton: React.FC<IRoundButtonProps> = ({
  isNextRoundButton,
  isDisabled,
  isHidden,
  onNextRound,
  onStartRound,
}) => {
  return (
    <Button
      className={classNames('battle-button', {
        'battle-button--next': isNextRoundButton,
        'battle-button--hidden': isHidden,
      })}
      color="ice-blue-2"
      text={
        <>
          <span>
            {isNextRoundButton ? 'Next Round' : isDisabled ? 'Choose the Targets' : "Let's Go!"}
          </span>
          {!isNextRoundButton && <img src="/images/snow-season/snowflake.png" alt="" />}
        </>
      }
      onClick={isNextRoundButton ? () => onNextRound?.() : () => onStartRound?.()}
      disabled={isNextRoundButton ? false : isDisabled}
    />
  );
};

export default memo(RoundButton);
