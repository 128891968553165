import React from 'react';
import Lottie from 'react-lottie-player';
import countdown from 'assets/animations/321.json';

const SnowballCountdown: React.FC<{ isPlay?: boolean }> = ({ isPlay = true }) => {
  return (
    <div className="snowball-countdown">
      {isPlay ? (
        <Lottie className="snowball-countdown--lottie" play animationData={countdown} />
      ) : (
        <p>VS</p>
      )}
    </div>
  );
};

export default SnowballCountdown;
