import { create } from 'zustand';
import { IBattle, IBattleProfile } from 'interface/battle';

interface IBattleStore {
  profile: IBattleProfile | null;
  battle: IBattle | null;
  history: IBattle[];
  isLoading: boolean;
  isRefilling: boolean;
  setProfile: (profile: IBattleProfile | null) => void;
  setBattle: (battle: IBattle) => void;
  setHistory: (history: IBattle[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsRefilling: (isRefilling: boolean) => void;
}

const useBattleStore = create<IBattleStore>((set) => ({
  profile: null,
  battle: null,
  history: [],
  isLoading: false,
  isRefilling: false,
  setProfile: (profile: IBattleProfile | null) => set(() => ({ profile })),
  setBattle: (battle: IBattle) => set(() => ({ battle })),
  setHistory: (history: IBattle[]) => set(() => ({ history })),
  setIsLoading: (isLoading: boolean) => set({ isLoading }),
  setIsRefilling: (isRefilling: boolean) => set({ isRefilling }),
}));

export default useBattleStore;
