import React, { useEffect, useState } from 'react';
import { memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Lottie from 'react-lottie-player';
import Button from 'components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { defaultAnimation } from 'styles/animations';
import classNames from 'classnames';
import { useLuck } from 'hooks/snow/useLuck';
import Stars from 'components/SnowSeason/Stars';
import { createBattleProfile } from 'api/battle';
import useBattleStore from 'store/battle.store';

import './Tutorial.scss';

const MAX_LEVEL = 5;

const Tutorial: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationStep = Number(location.search.split('=')[1]);
  const [animationData, setAnimationData] = useState<any>(null);
  const [step, setStep] = useState(0);
  const [animatedSkills, setAnimatedSkills] = useState<{ value: number; isAnimated: boolean }[]>([
    { value: 0, isAnimated: false },
    { value: 0, isAnimated: false },
    { value: 0, isAnimated: false },
    { value: 0, isAnimated: false },
    { value: 0, isAnimated: false },
  ]);
  const [animatedStars, setAnimatedStars] = useState<{ value: boolean; isAnimated: boolean }[]>([
    { value: false, isAnimated: false },
    { value: false, isAnimated: false },
    { value: false, isAnimated: false },
    { value: false, isAnimated: false },
    { value: false, isAnimated: false },
  ]);
  const { hapiScoreSkill, levelSkill, tasksSkill, partnersNFTsSkill, pitchTalkNftsSkill, stars } =
    useLuck();
  const [isLoading, setIsLoading] = useState(false);
  const battleProfile = useBattleStore(({ profile }) => profile);
  const setBattleProfile = useBattleStore(({ setProfile }) => setProfile);

  const skills = [
    { type: 'level', title: 'Level:', subtitle: 'Your overall progress so far', value: levelSkill },
    {
      type: 'tasks',
      title: 'Completed Tasks:',
      subtitle: 'Completed snowy adventures',
      value: tasksSkill,
    },
    {
      type: 'pitchTalkNfts',
      title: 'PitchTalk NFT:',
      subtitle: 'Special collectibles boosting your power',
      value: pitchTalkNftsSkill,
    },
    {
      type: 'partnersNfts',
      title: 'Partner NFTs:',
      subtitle: 'Support from special allies',
      value: partnersNFTsSkill,
    },
    {
      type: 'hapi',
      title: 'Hapi Score:',
      subtitle: 'Increase your chances of victory!',
      value: hapiScoreSkill,
    },
  ];

  const buttonText = step === 0 ? "Let's Begin" : step === 1 ? 'Got It' : 'Lets Play!';

  const handleButtonClick = async () => {
    if (step === 2) {
      try {
        setIsLoading(true);
        if (!battleProfile) {
          const profile = await createBattleProfile();
          setBattleProfile(profile);
          if (profile) {
            navigate('/snow-season');
            return;
          }
        }
        if (battleProfile) {
          navigate('/snow-season');
          return;
        }

        navigate('/');
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setStep(step + 1);
    }
  };

  const stepContent = {
    0: (
      <motion.div key="0" className="tutorial-page__content-step-0" {...defaultAnimation}>
        <h2>Welcome to the Pitchy's Snowy Adventure!</h2>
        <p>
          Help Pitchy collect treasures and <br /> test your snowy skills in friendly duels!
        </p>
        <img src="/images/snow-season/tutorial.png" alt="Tutorial" />
      </motion.div>
    ),
    1: (
      <motion.div key="1" className="tutorial-page__content-step-1" {...defaultAnimation}>
        <h2>How to Play</h2>
        <ul>
          <li>
            <b>1</b> <span>Each duel has 5 rounds</span>
          </li>
          <li>
            <b>2</b> <span>Choose where to throw and what to defend each round.</span>
          </li>
          <li>
            <b>3</b> <span>Earn points by hitting and dodging. Higher scores win!</span>
          </li>
          <li>
            <b>4</b> <span>Boost your luck and climb the leaderboard!</span>
          </li>
        </ul>
        <div className="tutorial-container">
          <img src="/images/snow-season/tutorial-container.png" alt="" />
          {animationData && (
            <Lottie
              className={classNames('hiw-animation')}
              play
              loop
              animationData={animationData}
            />
          )}
        </div>
      </motion.div>
    ),
    2: (
      <motion.div key="2" className="tutorial-page__content-step-2" {...defaultAnimation}>
        <h2>Discover Your Snowy Skills!</h2>
        <p className="tutorial-page__content-step-2__description">
          Pitchy is analyzing your snowy style... Let's see how lucky you are!
        </p>
        <div className="tutorial-page__content-step-2__skills">
          {skills.map((skill, index) => (
            <div key={skill.title} className="tutorial-page__content-step-2__skills-item">
              <div className="tutorial-page__content-step-2__skills-item--title">
                <h3>{skill.title}</h3>
                <p>{skill.subtitle}</p>
              </div>
              <div
                className={classNames(
                  'tutorial-page__content-step-2__skills-item--progress',
                  `tutorial-page__content-step-2__skills-item--progress-${skill.type}`,
                )}>
                <motion.div
                  className="tutorial-page__content-step-2__skills-item--progress-bar"
                  style={{
                    width: `${skill.value}%`,
                    maxWidth: skill.type === 'level' ? `${MAX_LEVEL * 10}%` : '100%',
                  }}
                  initial={{ width: '0%' }}
                  animate={{ width: `${animatedSkills[index].value}%` }}
                  transition={{ duration: 1, ease: 'easeInOut' }}
                />
                {skill.type === 'level' && (
                  <div className="tutorial-page__content-step-2__skills-item--progress-bar--disabled" />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="tutorial-page__content-step-2__luck">
          <h2>Your Snowy Luck:</h2>
          <Stars stars={animatedStars.map((star) => star.value)} isAnimate={true} />
          {animatedStars.every((star) => star.isAnimated) && (
            <motion.p {...defaultAnimation}>Don't worry! You can boost it as you play!</motion.p>
          )}
        </div>
      </motion.div>
    ),
  };

  useEffect(() => {
    if (step === 2) {
      const animateSkills = () => {
        const skillAnimationDelay = 1000;
        skills.forEach((skill, index) => {
          setTimeout(() => {
            setAnimatedSkills((prev) => {
              const newSkills = [...prev];
              newSkills[index] = { value: skill.value, isAnimated: true };
              return newSkills;
            });
          }, index * skillAnimationDelay);
        });

        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      };

      animateSkills();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (animatedSkills.every((skill) => skill.isAnimated)) {
      setTimeout(() => {
        stars.forEach((value, index) => {
          setTimeout(() => {
            setAnimatedStars((prevStars) => {
              const updatedStars = [...prevStars];
              updatedStars[index] = { value, isAnimated: true };
              return updatedStars;
            });
          }, index * 500);
        });
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animatedSkills]);

  useEffect(() => {
    const loadHIWAnimation = async () => {
      const data = await import('assets/animations/hiw.json');
      setAnimationData(data.default);
    };
    loadHIWAnimation();
  }, []);

  useEffect(() => {
    if (locationStep) {
      setStep(locationStep);
    }
  }, [locationStep]);

  return (
    <motion.div className="tutorial-page" {...defaultAnimation}>
      <div className="tutorial-page__header">
        <div
          className={classNames('tutorial-page__header-step', {
            'tutorial-page__header-step--active': step >= 0,
          })}
          onClick={() => setStep(0)}
        />
        <div
          className={classNames('tutorial-page__header-step', {
            'tutorial-page__header-step--active': step >= 1,
          })}
          onClick={() => setStep(1)}
        />
        <div
          className={classNames('tutorial-page__header-step', {
            'tutorial-page__header-step--active': step >= 2,
          })}
          onClick={() => setStep(2)}
        />
      </div>
      <div className="tutorial-page__content">
        <AnimatePresence mode="wait" initial={true}>
          {stepContent[step as keyof typeof stepContent]}
        </AnimatePresence>
      </div>
      <div className="tutorial-page__footer">
        <Button
          disabled={isLoading}
          color="ice-blue"
          text={buttonText}
          onClick={handleButtonClick}
        />
      </div>
      {step === 2 && animatedStars.every((star) => star.isAnimated) && (
        <motion.img
          className="tutorial-page__shine"
          src="/images/snow-season/shine.svg"
          alt=""
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 1, 0] }}
          transition={{
            opacity: {
              duration: 3,
              repeat: Infinity,
              ease: 'easeInOut',
            },
          }}
        />
      )}
    </motion.div>
  );
};

export default memo(Tutorial);
