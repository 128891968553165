import { IUser } from './user';

export interface IBattleProfile {
  id: string;
  user: IUser;
  userId: string;
  username: string;
  level: number;

  energy: number;
  nextEnergyRefreshAt: Date;

  score: number;
  wins: number;
  losses: number;
  draws: number;
  totalPlayed: number;

  battlesAsPlayer: IBattle[];
  battlesAsOpponent: IBattle[];

  hitChance: number;
  dodgeChance: number;

  defenseHitChance: number;
  defenseDodgeChance: number;

  createdAt: Date;
}

export interface IBattle {
  id: number;
  player: IBattleProfile;
  opponent: IBattleProfile;
  playerId: string;
  opponentId: string;

  status: EBattleStatus;

  playerScore: number;
  opponentScore: number;

  playerHitChance: number;
  playerDodgeChance: number;
  opponentHitChance: number;
  opponentDodgeChance: number;

  winnerId: string;
  loserId: string;

  isRevengeAvailable: boolean;
  isRevenge: boolean;

  rounds: IRound[];

  createdAt: Date;
  updatedAt: Date;
}

export interface IRound {
  id: number;
  roundNumber: number;
  battleId: number;

  playerId: number;
  opponentId: number;

  playerScore: number;
  opponentScore: number;

  playerAttackTarget: EBattleTarget;
  opponentAttackTarget: EBattleTarget;

  playerDefenseTarget: EBattleTarget;
  opponentDefenseTarget: EBattleTarget;

  playerAttackResult: EBattleAttackResult;
  opponentAttackResult: EBattleAttackResult;

  playerDefenseResult: EBattleDefenseResult;
  opponentDefenseResult: EBattleDefenseResult;

  createdAt: Date;
  updatedAt: Date;
}

export enum EBattleAttackResult {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  LUCKY_HIT = 'LUCKY_HIT',
}

export enum EBattleDefenseResult {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  LUCKY_DODGE = 'LUCKY_DODGE',
}

export enum EBattleStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum EBattleTarget {
  BODY = 'BODY',
  HEAD = 'HEAD',
}

export enum ERoundResult {
  WIN = 'WIN',
  DRAW = 'DRAW',
  LOSE = 'LOSE',
}
