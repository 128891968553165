import { EBattleTarget, IBattle, IBattleProfile } from 'interface/battle';
import api from './APIService';
import { handleApiError } from './errorHandlerService';

export const createBattleProfile = async (): Promise<IBattleProfile | null> => {
  try {
    const response = await api.post<IBattleProfile>('battle-profiles');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getBattleProfile = async (): Promise<IBattleProfile | null> => {
  try {
    const response = await api.get<IBattleProfile>('battle-profiles');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getBattles = async (page: number, limit: number): Promise<IBattle[]> => {
  try {
    const response = await api.get<IBattle[]>(`/battles?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return [];
  }
};

export const getCurrentBattle = async (): Promise<IBattle | null> => {
  try {
    const response = await api.get<IBattle>('battles/current');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getBattle = async (id: string): Promise<IBattle | null> => {
  try {
    const response = await api.get<IBattle>(`battles/${id}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const createBattle = async (timeout: number = 20000): Promise<IBattle | null> => {
  api.defaults.timeout = timeout;
  try {
    const response = await api.post<IBattle>('battles');
    return response.data;
  } catch (error: any) {
    return null;
  }
};

export const createBattleRound = async (
  battleId: number,
  playerAttackTarget: EBattleTarget,
  playerDefenseTarget: EBattleTarget,
): Promise<IBattle | null> => {
  try {
    const response = await api.post<IBattle>(`battles/${battleId}/rounds`, {
      playerAttackTarget,
      playerDefenseTarget,
    });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};
