import React, { useState, useEffect, memo } from 'react';
import Lottie from 'react-lottie-player';
import classNames from 'classnames';

const SnowballAnimation: React.FC<{
  isAnimation?: boolean;
  isLeft?: boolean;
  isLuck?: boolean;
}> = ({ isAnimation = false, isLeft = false, isLuck = true }) => {
  const [animationData, setAnimationData] = useState<any>(null);
  const [luckAnimationData, setLuckAnimationData] = useState<any>(null);

  useEffect(() => {
    const loadSnowballAnimation = async () => {
      const data = isLeft
        ? await import('assets/animations/left_snowball.json')
        : await import('assets/animations/right_snowball.json');
      setAnimationData(data.default);
    };
    loadSnowballAnimation();

    const loadLuckAnimation = async () => {
      const data = isLeft
        ? await import('assets/animations/luck1.json')
        : await import('assets/animations/luck2.json');
      setLuckAnimationData(data.default);
    };
    loadLuckAnimation();
  }, [isLeft]);

  return (
    <div className="snow-animation">
      {isAnimation && animationData && (
        <Lottie
          className={classNames('snowball-animation', {
            'snowball-animation--left': isLeft,
            'snowball-animation--right': !isLeft,
          })}
          play
          animationData={animationData}
        />
      )}
      {isLuck && luckAnimationData && (
        <Lottie
          className={classNames('luck-animation', {
            'luck-animation--left': isLeft,
            'luck-animation--right': !isLeft,
          })}
          play
          animationData={luckAnimationData}
        />
      )}
    </div>
  );
};

export default memo(SnowballAnimation);
