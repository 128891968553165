import classNames from 'classnames';
import { motion } from 'framer-motion';
import { isNumber } from 'lodash';
import React, { memo } from 'react';
import { defaultAnimation } from 'styles/animations';

interface IPoints {
  playerPoints: number;
  opponentPoints: number;
  isFinishedAnimations: boolean;
}

const Points: React.FC<IPoints> = ({ isFinishedAnimations, playerPoints, opponentPoints }) => {
  return (
    isFinishedAnimations &&
    isNumber(playerPoints) &&
    isNumber(opponentPoints) && (
      <>
        <motion.div
          {...defaultAnimation}
          className={classNames('round-selects__points', {
            'round-selects__points--winner': playerPoints > opponentPoints,
            'round-selects__points--player': true,
          })}>
          +{playerPoints}
        </motion.div>
        <motion.div
          {...defaultAnimation}
          className={classNames('round-selects__points', {
            'round-selects__points--winner': opponentPoints > playerPoints,
            'round-selects__points--opponent': true,
          })}>
          +{opponentPoints}
        </motion.div>
      </>
    )
  );
};

export default memo(Points);
